import { Component, ViewChild } from '@angular/core';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { BaseForm } from '../../../base-form';
import { ActivatedRoute, Router } from '@angular/router';
import { StockApiService } from '../../../../service/api/stock-api.service';
import { build, stack, list, buildParams } from '../../../../common/classes/request-builder';
import { FincasApiService } from '../../../../service/api/fincas-api.service';
import { ProductosApiService } from '../../../../service/api/productos-api';
import { AppFormRequest } from '../../../app-common/form-request/app-form-request';
import { Utils } from '../../../../common/utils';
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import { RequestButtonComponent } from '../../../../common/components/request-button/request-button.component';
import { StorageManager } from '../../../../common/storage-manager.class';
import { FormRequestTypes } from '../../../../common/classes/form-request';
import { VentasApiService } from '../../../../service/api/ventas-api.service';
import { ClientesApiService } from '../../../../service/api/clientes-api.service';
import { SiexApiService } from '../../../../service/api/siex-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ProductModel } from 'src/app/models/productos/product.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ItemValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { StockageModel } from 'src/app/models/stock/stokage.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { Compradores } from 'src/app/models/compradores.model';
@Component({
    selector: 'app-entradas-form',
    templateUrl: './entradas-form.component.html',
    styleUrls: ['./entradas-form.component.scss']
})
export class EntradasFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton: RequestButtonComponent = new RequestButtonComponent();

    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public showStockMarcaComercial = environment.features.showStockMarcaComercial;
    public showStockTipoDeDato = environment.features.showStockTipoDeDato;
    public showStockProveedor = environment.features.showStockProveedor;
    public showStockEnvase = environment.features.showStockEnvase;
    public showStockMotivo = environment.features.showStockMotivo;
    public showStockSemilla = environment.features.showStockSemilla;
    public showStockUbicacion = environment.features.showStockUbicacion;
    public showStockProveedorClienteDropdown = environment.features.showStockProveedorClienteDropdown;
    public showStockCifProveedor = environment.features.showStockCifProveedor;
    public persisteIdComercio = environment.features.persisteIdComercio;
    public hasStockParcelas = environment.features.hasStockParcelas;
    public showStockUbicacionDestino = environment.features.showStockUbicacionDestino;
    public showStockAdditionalFields = environment.features.showStockAdditionalFields;
    public columnas_distribucion = environment.features.showStockProductsColsDistribution;
    public columnas_semilla = this.appName === 'pyf' ? '6' : environment.features.showStockSemillasColsDistribution;
    public hasPlantas = environment.features.hasPlantas;

    public override model: StockageModel = {stockage_real: 0, stockage_teorico: 0, fecha: '', unidades_temp: 0, unidades: 0};
    public modelLinea: StockageModel = {stockage_real: 0, stockage_teorico: 0, fecha: '', unidades_temp: 0, unidades: 0};

    public formRequest: AppFormRequest = new AppFormRequest();
    public error = '';
    public interval: string | number | NodeJS.Timeout | undefined;


    public fincas: TypeAheadInterface<FincasModel> = list(['...', null]);
    public productos1: TypeAheadInterface<ProductModel> = list(['...', null]);
    public productos2: TypeAheadInterface<ProductModel> = list(['...', null]);
    public productos3: TypeAheadInterface<ProductModel> = list(['...', null]);
    public productosConSemillas1: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public productosConSemillas2: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public productosConSemillas3: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public productosConSemillas4: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public productosConSemillas5: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public productosAll: TypeAheadInterface<ProductModel> = list(['...', null]);
    public cultivosAll: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public tiposDeDato = list(['...', null], 'Fitosanitario', 'Fertilizante');
    public variedad: TypeAheadInterface<string> = list(['...', null]);
    public variedad2: TypeAheadInterface<string> = list(['...', null]);
    public variedad3: TypeAheadInterface<string> = list(['...', null]);
    public ubicaciones: TypeAheadInterface<string> = list(
        ['...', null],
        'I-1',
        'I-2',
        'I-3',
        'I-4',
        'I-5',
        'I-6',
        'Sombrajos 1',
        'Sombrajos 2',
        'Sombrajos 3',
        'Sombrajos 4'
    );
    public ubicacionesViveros: TypeAheadInterface<string> = list(
        ['...', null],
        'I-1',
        'I-2',
        'I-3',
        'I-4',
        'I-5',
        'I-6',
        'S-1',
        'S-2',
        'S-3',
        'S-4'
    );
    public comercios: TypeAheadInterface<Compradores> = list(['...', null]);
    public clientes: TypeAheadInterface<ClienteModel> = list(['...', null]);
    public aux_comercios: TypeAheadInterface<Compradores> = list(['...', null]);
    public sectores: TypeAheadInterface<SectorModel> = list(['...', null]);
    public parcelas: TypeAheadInterface<ParcelasModel> = list(['...', null]);
    public parcelasDestino: TypeAheadInterface<ParcelasModel> = list(['...', null]);
    public articulos: TypeAheadInterface<ProductModel> = list(['...', null]);
    public motivos: TypeAheadInterface<string> = list(
        ['...', null],
        'Desperdicio',
        'Mala calidad',
        'Falta de agua',
        'Buen estado',
        'Movimiento',
        'Arranque'
    );
    public motivosCovidai: TypeAheadInterface<string> = list(
        ['...', null],
        'Desperdicio',
        '1ª',
        '2ª',
        'Falta de agua',
        'Reposición',
        'Climatología Adversa',
        'Movimiento'
    );
    public lista_semilla: TypeAheadInterface<string> = list(['...', null], 'R1', 'R2', 'S');
    public lista_semilla2: TypeAheadInterface<string> = list(['...', null], 'R1', 'R2', 'S');
    public lista_semilla3: TypeAheadInterface<string> = list(['...', null], 'R1', 'R2', 'S');
    public tipoEntrada: TypeAheadInterface<string> = list(['...', null], ['Entrada', 'E'], ['Salida', 'S'], ['Producción', 'P']);

    public formFieldsTop: ItemInterface<Compradores | TypeAheadInterface<string> | object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true,
            visible: !environment.features.showTareasMto
        },
        {
            field: 'fecha',
            label: 'Fecha',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        {
            field: 'tipo',
            label: 'Tipo',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: this.appName === 'ava' ? list(['Entrada', 'E']) : this.tipoEntrada

        },
        {
            field: 'motivo',
            label: 'Motivo',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            visible: this.showStockMotivo,
            values: this.appName === 'covidai' ? this.motivosCovidai : this.motivos
        },
        {
            field: 'tipo_dato',
            label: 'Tipo de producto',
            inputType: {type: InputType.DROPDOWN},
            values: this.tiposDeDato,
            visible: this.showStockTipoDeDato && this.appName !== 'viverosblanco',
            required: true
        },
        {
            field: 'proveedor',
            label: 'Proveedor',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockProveedor && !this.showStockProveedorClienteDropdown
        },
        {
            field: 'id_comercio',
            label: 'Proveedor',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            valuePrimaryKey: 'id',
            values: this.comercios,
            visible: this.showStockProveedor && this.showStockProveedorClienteDropdown,
            
            // TODO: remove this when we have support for multiple
            // @ts-expect-error El desplegable no muestra ningún valor si se devuelve fuera del condiciconal.
            filter: (it) => {
                if (this.appName !== 'covidai') {
                    const comerc: Compradores = it.value as Compradores;
                    const tipo = this.model.tipo;

                    if (it.value == null) {
                        return true;
                    }
                    if (comerc.tipo == null) {
                        return false;
                    }
    
                    if (tipo != null && (tipo === 'S' && comerc.tipo === 'Cliente') ||
                            (tipo === 'E' && comerc.tipo === 'Proveedor')
                    ) {
                        return true;
                    }
                    
                } else {
                    this.aux_comercios.filtered = [...this.comercios.filtered];
                }
            }
        },
        {
            field: 'cif_proveedor',
            label: 'NIF/CIF',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockCifProveedor
        },
        {
            field: 'albaran',
            label: 'Albarán/Factura',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'ubicacion',
            label: 'Ubicación',
            values: this.ubicacionesViveros,
            inputType: {type: InputType.DROPDOWN},
            visible: this.showStockUbicacion && this.appName !== 'bravosol',
            valuePrimaryKey: 'id',
        },
        {
            field: 'id_sector',
            label: 'Bancal',
            inputType: {type: InputType.DROPDOWN},
            values: this.sectores,
            valuePrimaryKey: 'id',
            visible: this.appName === 'covidai',
            filter: (it: { value: SectorModel; }) => {
                const sector: SectorModel = it.value;
                return sector.id_finca === (this.fincas.selected as FincasModel || {}).id;
            },
        },
        {
            field: 'ubicacion',
            label: this.appName === 'covidai' ? 'Ubicación salida' : 'Ubicación',
            values: this.parcelas,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            visible: this.appName === 'covidai' || this.appName === 'bravosol',
            valuePrimaryKey: 'id',
            filter: (it: { value: ParcelasModel; }) => {
                const parcela: ParcelasModel = it.value as ParcelasModel;

                if (this.appName === 'covidai' && this.sectores.selected) {
                    return parcela.id_sector === (this.sectores.selected as SectorModel || {}).id;
                } else {
                    return parcela.id_finca === (this.fincas.selected as FincasModel|| {}).id;
                }
            },
        },
        {
            field: 'ubicacion_destino',
            label: 'Ubicación de destino',
            inputType: {type: this.appName === 'covidai' ? InputType.DROPDOWN_SEARCH : InputType.DROPDOWN},
            values: this.appName === 'covidai' || this.appName === 'bravosol' ? this.parcelasDestino : this.ubicacionesViveros,
            visible: this.appName === 'covidai' || this.appName === 'bravosol' || this.appName === 'viverosblanco',
            valuePrimaryKey: 'id'
        },
        {
            field: 'fecha_injerto',
            label: 'Fecha injerto',
            inputType: {type: InputType.CALENDAR},
            visible: this.appName === 'viverosblanco',
            disabled: this.appName === 'covidai' ? false : true
        },
        {
            field: 'articulo',
            label: 'Artículo',
            inputType: {type: InputType.DROPDOWN},
            values: this.articulos,
            visible: this.appName === 'bravosol',
            valuePrimaryKey: 'cod_producto',
        },
    ];

    public formFieldsLineas: ItemInterface<object>[] = [
        {
            field: 'id_producto1',
            label: this.appName === 'vginer' ? 'Fertilizante' : this.appName === 'covidai' ? 'Variedad' : 'Producto/Marca comercial',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos1,
            valuePrimaryKey: 'id'
        },
        {
            field: 'marca_comercial1',
            label: 'Marca comercial',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockMarcaComercial
        },
        { 
            field: 'unidades1', 
            label: 'Cantidad', 
            inputType: {type: InputType.EDIT_TEXT} },
        { 
            field: 'lote1', 
            label: 'Lote', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'id_producto2',
            hasLabel: false,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos2,
            valuePrimaryKey: 'id'
        },
        { 
            field: 'marca_comercial2', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showStockMarcaComercial 
        },
        { 
            field: 'unidades2', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT}
        },
        { 
            field: 'lote2', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT} 
        },
        {
            field: 'id_producto3',
            hasLabel: false,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos3,
            valuePrimaryKey: 'id'
        },
        { 
            field: 'marca_comercial3', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showStockMarcaComercial 
        },
        { 
            field: 'unidades3', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT}
        },
        { 
            field: 'lote3', 
            hasLabel: false, 
            inputType: {type: InputType.EDIT_TEXT}
        },
    ];

    public formFieldsLineasWithTipoDato: ItemInterface<object>[] = [
        /**************
         * Producto 1 *
         * ************
         * 
         * 
         * 
         */
        {
            field: 'id_tipo1',
            label: this.appName === 'viverosblanco' ||
                this.appName === 'covidai' ?
                'Producto' :
                this.appName === 'momparler' ?
                    'Producto o planta 1' :
                    'Producto',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productosConSemillas1,
            valuePrimaryKey: 'id',
            required: this.showStockTipoDeDato
        },
        {
            field: 'id_variedad',
            label: 'Variedad 1',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.variedad,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'tipo_semilla',
            label: 'Categoría 1',
            inputType: {type: InputType.DROPDOWN},
            values: this.lista_semilla,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'unidades1',
            label: 'Cantidad 1',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'envase1',
            label: 'Envase 1',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockEnvase,
        },
        {
            field: 'lote1',
            label: 'Lote propio 1',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: this.appName === 'bravosol' ? 'lote_conselleria1' : 'lote1_conselleria',
            label: 'Lote conselleria 1',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'covidai' || this.appName === 'bravosol'
        },
        {
            field: 'unidad_medida1',
            label: 'Unidad Medida 1',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },

        /**************
    * Producto 2 *
    * ************
    * 
    * 
    * 
    */
        {
            field: 'id_tipo2',
            //hasLabel: false,
            label: this.appName === 'momparler' ? 'Producto o planta 2' : 'Producto',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productosConSemillas2,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'id_variedad2',
            label: 'Variedad 2',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.variedad2,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'tipo_semilla2',
            label: 'Categoría 2',
            inputType: {type: InputType.DROPDOWN},
            values: this.lista_semilla2,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'unidades2',
            //hasLabel: false, 
            label: 'Cantidad 2',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'envase2',
            label: 'Envase 2',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockEnvase

        },
        {
            field: 'lote2',
            // hasLabel: false, 
            label: 'Lote propio 2',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'lote_conselleria2',
            label: 'Lote conselleria 2',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'bravosol'
        },
        {
            field: 'unidad_medida2',
            label: 'Unidad Medida 2',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },

        /**************
    * Producto 3 *
    * ************
    * 
    * 
    * 
    */
        {
            field: 'id_tipo3',
            //hasLabel: false,
            label: this.appName === 'momparler' ? 'Producto o planta 3' : 'Producto',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productosConSemillas3,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'id_variedad3',
            label: 'Variedad 3',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.variedad3,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'tipo_semilla3',
            label: 'Categoría 3',
            inputType: {type: InputType.DROPDOWN},
            values: this.lista_semilla3,
            visible: this.appName === 'pyf',
            style: { 'display': 'none' }
        },
        {
            field: 'unidades3',
            //hasLabel: false, 
            label: 'Cantidad 3',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'envase3',
            label: 'Envase 3',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockEnvase
        },
        {
            field: 'lote3',
            //hasLabel: false, 
            label: 'Lote propio 3',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName !== 'viverosblanco' && this.appName !== 'covidai'
        },
        {
            field: 'lote_conselleria3',
            label: 'Lote conselleria 3',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'bravosol'
        },
        {
            field: 'unidad_medida3',
            label: 'Unidad Medida 3',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },

        /**************
    * Producto 4 *
    * ************
    * 
    * 
    * 
    */
        {
            field: 'id_tipo4',
            //hasLabel: false,
            label: this.appName === 'momparler' ? 'Producto o planta 4' : 'Producto',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productosConSemillas4,
            valuePrimaryKey: 'id',
            visible: this.showStockAdditionalFields
        },
        {
            field: 'unidades4',
            //hasLabel: false, 
            label: 'Cantidad 4',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },
        {
            field: 'envase4',
            label: 'Envase 4',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockEnvase && this.showStockAdditionalFields
        },
        {
            field: 'lote4',
            //hasLabel: false, 
            label: 'Lote propio 4',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },
        {
            field: 'unidad_medida4',
            label: 'Unidad Medida 4',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },
        
        /**************
    * Producto 5 *
    * ************
    * 
    * 
    * 
    */
        {
            field: 'id_tipo5',
            //hasLabel: false,
            label: this.appName === 'momparler' ? 'Producto o planta 5' : 'Producto',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productosConSemillas5,
            valuePrimaryKey: 'id',
            visible: this.showStockAdditionalFields
        },
        {
            field: 'unidades5',
            //hasLabel: false, 
            label: 'Cantidad 5',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },
        {
            field: 'envase5',
            label: 'Envase 5',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockEnvase && this.showStockAdditionalFields

        },
        {
            field: 'lote5',
            //hasLabel: false, 
            label: 'Lote propio 5',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        },
        {
            field: 'unidad_medida5',
            label: 'Unidad Medida 5',
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.showStockAdditionalFields
        }
    ];

    public unidades = list(['...', null], 'Kilos', 'Litros');

    public formFieldsUnidadesMedida: ItemInterface<object>[] = [
        {
            field: 'unidades_medida',
            label: 'Unidades de medida',
            inputType: {type: InputType.DROPDOWN},
            values: this.unidades,
            visible: this.appName === 'pyf' ? false : true
        },
    ];

    public formFieldsBottom: ItemInterface<object>[] = [
        {
            field: 'observaciones',
            label: this.appName === 'armentia' ?
                'Observaciones (tratamiento)' :
                'Observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        }
    ];
    public variedades: TypeAheadInterface<VisitasVariedadMesModel> | TypeAheadInterface<VisitasVariedadMesModel>[] = list(['...', null]);
    public auxCultivos: TypeAheadInterface<VisitasVariedadMesModel> = list(['...', null]);
    public faltanUnidades = false;

    public loadingData = true;
    productos: TypeAheadInterface<ProductModel> = list(['...', null]);


    constructor(
        public siexApi: SiexApiService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public override router: Router,
        public override route: ActivatedRoute,
        public dashboardEvents: DashboardService,
        public entradasApi: StockApiService,
        public ventasApi: VentasApiService,
        public clientesApi: ClientesApiService
    ) {
        super(
            route,
            router,
            dashboardEvents,
            entradasApi.entradas.PUT,
            entradasApi.entradas.POST,
            'entradas',
            'Editar movimiento',
            'Registrar movimiento'
        );
        if (this.showStockEnvase) {
            this.columnas_distribucion = 4;
        }
    }

    ngOnInit() {
        this.adaptTipoDatoOptions();


        const fieldsToSend = this.getFieldsToSend();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setGetRequest(this.entradasApi.entradas.GET)
            .setPostRequest(this.entradasApi.entradas.POST)
            .setPutRequest(this.entradasApi.entradas.PUT)
            .setModel(this.model)
            .setFormFields(this.formFieldsTop.concat(this.formFieldsBottom).concat(this.formFieldsUnidadesMedida))
            .setFieldsToSend((fieldsToSend as string[])
                .concat(
                    this.formFieldsTop.map(it => it.field ?? ''),
                    this.formFieldsBottom.map(it => it.field ?? '')
                )
            );

        this.expandFormRequest();

        this.formRequest.load();

        this.softInit(this.getType());
    }// ngOnInit();

    public submit() {
        if (this.appName !== 'covidai') {
            delete this.model.id_sector;
        }// if();

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        } else {

            if (this.showStockTipoDeDato) {

                if (this.checkUnitsAndProducts() === false) {
                    return;
                }
            }
            if (this.checkProductAndUnits() === false) {
                return;
            }
            this.formRequest.send();
        }

    }

    public formChanges(tag: string) {
        this.requestButton.error = '';

        //Generar lista de opciones de variedades en función del cultivo seleccionado
        //if ((this.model['tipo_dato'] as string).toLowerCase().localeCompare("semilla") == 0 && tag === 'id_tipo1') {
        if (this.model.tipo_dato != null) {
            if ((this.model.tipo_dato as string).toLowerCase().localeCompare('semilla') === 0) {
                if (tag === 'id_tipo1') {
                    if (this.productosConSemillas1.selected == null) {
                        clearInterval(this.interval);
                    } else {
                        //this.variedad.filtered = [{ label: 'Procesando...', value: null }];
                        const cultivoSelect: string = (this.productosConSemillas1.selected as VisitasVariedadMesModel).cultivo ?
                            (this.productosConSemillas1.selected as VisitasVariedadMesModel).cultivo : '';

                        this.auxCultivos.filtered.forEach(cultivo => {
                            if ((cultivo.label as string).localeCompare(cultivoSelect) === 0) {
                                this.variedad.filtered.push(
                                    { 
                                        label: (cultivo.value as VisitasVariedadMesModel).variedad, 
                                        value: (cultivo.value as VisitasVariedadMesModel).id_variedad ?? '' }
                                );
                            }
                            this.variedad.selected = this.model.id_variedad ? this.model.id_variedad : null;
                            this.lista_semilla.selected = this.model.tipo_semilla ? this.model.tipo_semilla : null;
                        });
                    }
                }

                if (this.appName === 'pyf') {
                    if (this.productosConSemillas2.selected == null) {
                        clearInterval(this.interval);
                    } else {
                        if (tag === 'id_tipo2') {
                            //this.variedad2.filtered = [{ label: 'Procesando...', value: null }];
                            const cultivoSelect: string = (this.productosConSemillas2.selected as VisitasVariedadMesModel).cultivo ?
                                (this.productosConSemillas2.selected as VisitasVariedadMesModel).cultivo : '';
                            this.auxCultivos.filtered.forEach(cultivo => {
                                if ((cultivo.label as string).localeCompare(cultivoSelect) === 0) {
                                    this.variedad2.filtered.push(
                                        { 
                                            label: (cultivo.value as VisitasVariedadMesModel).variedad, 
                                            value: (cultivo.value as VisitasVariedadMesModel).id_variedad ?? '' 
                                        }
                                    );
                                }//if();
                            });
                            this.variedad2.selected = this.model.id_variedad2 ? this.model.id_variedad2 : null;
                            this.lista_semilla2.selected = this.model.tipo_semilla2 ? this.model.tipo_semilla2 : null;
                        }

                    }//if();

                    if (this.productosConSemillas3.selected == null) {
                        clearInterval(this.interval);
                    } else {
                        if (tag === 'id_tipo3') {
                            //this.variedad3.filtered = [{ label: 'Procesando...', value: null }];
                            const cultivoSelect: string = (this.productosConSemillas3.selected as VisitasVariedadMesModel).cultivo ?
                                (this.productosConSemillas3.selected as VisitasVariedadMesModel).cultivo : '';

                            this.auxCultivos.filtered.forEach(cultivo => {
                                if ((cultivo.label as string).localeCompare(cultivoSelect) === 0) {
                                    this.variedad3.filtered.push(
                                        { 
                                            label: (cultivo.value as VisitasVariedadMesModel).variedad, 
                                            value: (cultivo.value as VisitasVariedadMesModel).id_variedad ?? ''
                                        }
                                    );
                                }
                            });

                            this.variedad3.selected = this.model.id_variedad3 ? this.model.id_variedad3 : null;
                            this.lista_semilla3.selected = this.model.tipo_semilla3 ? this.model.tipo_semilla3 : '';
                        }
                    }//if();
                }//if();

                if (tag === 'id_comercio') {
                    this.model.proveedor = (this.comercios.selected as Compradores).razon_social;
                }
            }
        }
        if (tag === 'tipo') {
            this.changeLabelCliPro();
        }

        if (tag === 'tipo_dato') {
            this.model.semilla = '';
            this.model.tipo_semilla = '';
            this.model.unidad_medida1 = '';
            this.model.unidad_medida2 = '';
            this.model.unidad_medida3 = '';
            this.model.unidad_medida4 = '';
            this.model.unidad_medida5 = '';
            this.model.unidades_medida = '';
            this.model.id_tipo1 = '';
            this.model.id_tipo2 = '';
            this.model.id_tipo3 = '';
            this.model.id_tipo4 = '';
            this.model.id_tipo5 = '';

            const idxVariedad = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('id_variedad');
            const idxTipoSemilla = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('tipo_semilla');
            const idxVariedad2 = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('id_variedad2');
            const idxTipoSemilla2 = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('tipo_semilla2');
            const idxVariedad3 = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('id_variedad3');
            const idxTipoSemilla3 = this.formFieldsLineasWithTipoDato.map(it => it.field).indexOf('tipo_semilla3');

            if (this.model.tipo_dato === 'Semilla') {
                (this.formFieldsLineasWithTipoDato[idxVariedad] as ItemInterface<object>).style = { 'display': 'block' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla] as ItemInterface<object>).style = { 'display': 'block' };
                (this.formFieldsLineasWithTipoDato[idxVariedad2] as ItemInterface<object>).style = { 'display': 'block' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla2] as ItemInterface<object>).style = { 'display': 'block' };
                (this.formFieldsLineasWithTipoDato[idxVariedad3] as ItemInterface<object>).style = { 'display': 'block' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla3] as ItemInterface<object>).style = { 'display': 'block' };
            } else {
                (this.formFieldsLineasWithTipoDato[idxVariedad] as ItemInterface<object>).style = { 'display': 'none' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla] as ItemInterface<object>).style = { 'display': 'none' };
                (this.formFieldsLineasWithTipoDato[idxVariedad2] as ItemInterface<object>).style = { 'display': 'none' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla2] as ItemInterface<object>).style = { 'display': 'none' };
                (this.formFieldsLineasWithTipoDato[idxVariedad3] as ItemInterface<object>).style = { 'display': 'none' };
                (this.formFieldsLineasWithTipoDato[idxTipoSemilla3] as ItemInterface<object>).style = { 'display': 'none' };
            }

            this.formatTipos();
        }

        if (this.showStockAdditionalFields && this.getType() !== FormRequestTypes.EDIT && this.getType() !== FormRequestTypes.DUPLICATE) {
            if (tag === 'id_tipo1') {
                this.modelLinea.unidad_medida1 = (this.productosConSemillas1.selected as VisitasVariedadMesModel).unidades ?? '';
            } else if (tag === 'id_tipo2') {
                this.modelLinea.unidad_medida2 = (this.productosConSemillas2.selected as VisitasVariedadMesModel).unidades ?? '';
            } else if (tag === 'id_tipo3') {
                this.modelLinea.unidad_medida3 = (this.productosConSemillas3.selected as VisitasVariedadMesModel).unidades ?? '';
            } else if (tag === 'id_tipo4') {
                this.modelLinea.unidad_medida4 = (this.productosConSemillas4.selected as VisitasVariedadMesModel).unidades ?? '';
            } else if (tag === 'id_tipo5') {
                this.modelLinea.unidad_medida5 = (this.productosConSemillas5.selected as VisitasVariedadMesModel).unidades ?? '';
            }

        }

    }

    public changeLabelCliPro() {
        if (this.model.tipo === 'S') {
            this.formFieldsTop.map(dato => {
                if (dato.label === 'Proveedor') {
                    dato.label = 'Cliente';
                    if (this.appName === 'covidai') { dato.valuePrimaryKey = 'nombre'; }
                }
            });
        }
        if (this.model.tipo === 'E') {
            this.formFieldsTop.map(dato => {
                if (dato.label === 'Cliente') {
                    dato.label = 'Proveedor';
                    if (this.appName === 'covidai') { dato.valuePrimaryKey = 'razon_social'; }
                }
            });
        }
    }

    public formatTipos() {
        for (const i of [1, 2, 3, 4, 5]) {
            const idx = this.formFieldsLineasWithTipoDato
                .indexOf(this.formFieldsLineasWithTipoDato.find(it => it.field === ('id_tipo' + i)) as ItemInterface<object>);


            if (idx !== -1 && this.formFieldsLineasWithTipoDato[idx]) {
                switch (this.model.tipo_dato) {
                case 'Fitosanitario':
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).label = 'Fitosanitario';
                    (this.formFieldsLineasWithTipoDato[idx]?.values as ItemValuesInterface<object>).filtered = 
                    Array.prototype.concat(
                        (this.productosAll.filtered || [])
                            .filter(it => it && it.value && (it.value as ProductModel).tipo === 'fitosanitario')
                    );

                    break;
                case 'Fertilizante':
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).label = 'Fertilizante';

                    (this.formFieldsLineasWithTipoDato[idx]?.values as ItemValuesInterface<object>).filtered = 
                        Array.prototype.concat(
                            (this.productosAll.filtered || [])
                                .filter(it => it && it.value && (it.value as ProductModel).tipo === 'fertilizante')
                        );
                    break;
                case 'Ganadería':
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).label = 'Ganadería';
                    (this.formFieldsLineasWithTipoDato[idx]?.values as ItemValuesInterface<object>).filtered = 
                        Array.prototype.concat(
                            (this.productosAll.filtered || [])
                                .filter(it => it && it.value && (it.value as ProductModel).tipo === 'ganaderia')
                        );
                    break;
                case 'Semilla':
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).label = 'Semilla';
                    (this.formFieldsLineasWithTipoDato[idx]?.values as ItemValuesInterface<object>).filtered = 
                        Array.prototype.concat(
                            (this.cultivosAll.filtered || []).filter(it => it && it.value)
                        );
                    break;
                default:
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).label = 'Producto';
                    (this.formFieldsLineasWithTipoDato[idx] as ItemInterface<object>).values = list(['...', null]);
                    break;
                }
            }
        }
    }

    override async goBack() {
        this.router.navigate(['dashboard', 'stock-entradas']);

    }

    checkUnitsAndProducts() {


        if (!this.showStockTipoDeDato) {

            if ((this.modelLinea.unidades1 != null && (this.modelLinea.unidades1 as string).length > 0)) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }
            if ((this.modelLinea.unidades2 != null && (this.modelLinea.unidades2 as string).length > 0)) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }
            if ((this.modelLinea.unidades3 != null && (this.modelLinea.unidades3 as string).length > 0)) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }

        } else {

            if (
                (this.modelLinea.unidades1 != null &&
                    (this.modelLinea.unidades1 as string).length > 0) &&
                (this.modelLinea.id_tipo1 == null || (this.modelLinea.id_tipo1 as string).length === 0)
            ) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }
            if (
                (this.modelLinea.unidades2 != null &&
                    (this.modelLinea.unidades2 as string).length > 0) &&
                (this.modelLinea.id_tipo2 == null || (this.modelLinea.id_tipo2 as string).length === 0)
            ) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }
            if (
                (this.modelLinea.unidades3 != null &&
                    (this.modelLinea.unidades3 as string).length > 0) &&
                (this.modelLinea.id_tipo3 == null || (this.modelLinea.id_tipo3 as string).length === 0)) {
                this.requestButton.error = 'las unidades tienen que tener producto seleccionado';
                return false;
            }
        }
        return true;
    }

    checkProductAndUnits() {
        if (
            (this.modelLinea.id_tipo1 != null &&
                (this.modelLinea.id_tipo1 as string).length > 0) &&
            (this.modelLinea.unidades1 == null || (this.modelLinea.unidades1 as string).length === 0)
        ) {
            this.requestButton.error = 'Los productos tienen que tener unidades';
            return false;
        }

        if (
            (this.modelLinea.id_tipo2 != null &&
                (this.modelLinea.id_tipo2 as string).length > 0) &&
            (this.modelLinea.unidades2 == null || (this.modelLinea.unidades2 as string).length === 0)) {
            this.requestButton.error = 'Los productos tienen que tener unidades';
            return false;
        }

        if (
            (this.modelLinea.id_tipo3 != null &&
                (this.modelLinea.id_tipo3 as string).length > 0) &&
            (this.modelLinea.unidades3 == null || (this.modelLinea.unidades3 as string).length === 0)) {
            this.requestButton.error = 'Los productos tienen que tener unidades';
            return false;
        }
        return true;
    }


    selectUbicacion() {
        if (this.model.ubicacion != null) {
            this.parcelas.filtered.find(parcela => {
                if (parcela.value != null && (parcela.value as ParcelasModel).id === this.model.ubicacion) {
                    this.parcelas.selected = parcela.value;
                    return true;
                } else {
                    return false;
                }
            });
        }// if ():
    }// ()

    adaptTipoDatoOptions() {
        if (this.showStockSemilla) {
            this.tiposDeDato.filtered.push({ label: 'Semilla', value: 'Semilla' });

            if (this.appName === 'viverosblanco' || this.appName === 'covidai') {
                this.model.tipo_dato = 'Semilla';
            }// if ();

            if (this.appName === 'pyf') {
                this.tiposDeDato.filtered.push({ label: 'Ganadería', value: 'Ganadería' });
                this.tiposDeDato.filtered.push({ label: 'Otros', value: 'Otros' });
            }// if ();
        }// if ();
    }// ();

    getFieldsToSend() {
        const fieldsToSend = ['id', 'id_cliente', 'id_usuario', 'ids_productos', 'array_unidades', 'array_lotes'];

        if (this.showStockMarcaComercial) {
            fieldsToSend.push('array_marcas');
        }

        if (this.appName === 'pyf') {
            fieldsToSend.push('id_variedad2', 'id_variedad3', 'tipo_semilla2', 'tipo_semilla3');
        }

        if (this.showStockTipoDeDato) {
            fieldsToSend.push('ids_tipos', 'semilla', 'tipo_semilla', 'unidades_medida', 'id_variedad');
        }

        if (this.showStockEnvase) {
            fieldsToSend.push('array_envases');
        }

        if (this.persisteIdComercio) {
            fieldsToSend.push('id_comercio');
        }

        if (this.appName === 'covidai') {
            fieldsToSend.push('lote1_conselleria', 'lote1');
            fieldsToSend.push('array_lotes_conselleria');
        }

        if (this.appName === 'bravosol') {
            fieldsToSend.push('array_lotes_conselleria');
            fieldsToSend.push('articulo');
        }

        return fieldsToSend;
    }// ();

    getFieldsBuildRequests() {
        let params = {};

        if (this.appName === 'vginer') {
            params = { tipo: 'fertilizante' };
        }

        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(this.fincasApi.parcelasGET, [this.parcelas, this.parcelasDestino], 'nombre', true),
            buildParams(
                this.productosApi.productos.GET,
                params,
                [this.productos, this.productos1, this.productos2, this.productos3],
                'nombre'
            )
        ];


        if (this.showStockTipoDeDato) {
            builds.push(build(this.productosApi.productos.GET, this.productosAll, 'nombre'),
                build(
                    this.hasPlantas ? this.formApi.cultivosGET : this.siexApi.vistaCultivoVariedadGET,
                    [this.cultivosAll, this.auxCultivos], 'cultivo'
                ),
                build(this.ventasApi.compradoresGET, this.comercios, 'razon_social', true),
                build(this.clientesApi.clientesGET, this.clientes, 'nombre'));
        }


        if (this.appName === 'covidai') {
            builds.push(build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
                build(this.productosApi.cultivosPatron.GET, this.productosAll, 'nombre'),
                build(this.siexApi.portainjertosGET, [this.cultivosAll, this.auxCultivos], 'descripcion'),
                build(this.clientesApi.clientesGET, this.clientes, 'nombre'),
                buildParams(
                    this.siexApi.portainjertosGET,
                    params,
                    [this.productos1, this.productos2, this.productos3, this.articulos],
                    'familia'
                ));
        }
        return builds;
    }

    private initFormData() {
        const builds = this.getFieldsBuildRequests();

        return stack(builds).then(() => {
            this.interval = setInterval(() => {


                if (Object.keys(this.model).length === 0) {

                } else {
                    this.formChanges('id_tipo1');
                    this.formChanges('id_tipo2');
                    this.formChanges('id_tipo3');

                    if (this.getType() === 1 && this.appName === 'covidai') {
                        this.selectUbicacion();
                    }

                    clearInterval(this.interval);
                }
            }, 1000);

            this.cultivosAll.filtered = this.cultivosAll.filtered.filter((it, index) => {
                if (this.cultivosAll.filtered[index - 1]) {
                    if (this.cultivosAll.filtered[index - 1]?.label === it.label) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            });

            this.formRequest.update();
            if (this.fincas.filtered.length === 2 && this.getType() === 0) {
                this.model.id_finca = (this.fincas.filtered[1]?.value as FincasModel).id;
            }
            if (this.appName === 'ava') {
                this.model.tipo = 'E';
            }
            return;
        }
        ).catch (e => {
            console.log('catch en stack: ' + e);
        }
        );
    }

    private expandFormRequest() {
        this.formRequest.beforeLoad(resolve => {
            this.initFormData()
                .then(() => {
                    resolve(true);
                    return;
                }
                ).catch (e => {
                    console.log('catch en initFormData: ' + e);
                }
                );
        });

        this.formRequest.afterLoad(resolve => {
            if (this.appName === 'pyf') {
                this.fincas.filtered = [...this.fincas.filtered].reverse();
            }

            if ((this.getType() === FormRequestTypes.DUPLICATE)) {
                delete this.model.ids_productos;
                delete this.model.id;
                delete this.model.array_unidades;
                delete this.model.array_lotes;
                delete this.model.array_marcas;
                delete this.model.ids_tipos;
                delete this.model.array_envases;
                delete this.model.tipo_dato;

                if (this.showStockAdditionalFields) {
                    delete this.model.unidades_medida;
                }
            } else if (this.getType() === FormRequestTypes.EDIT) {
                const idsProductos = (this.model.ids_productos || '').split(';');
                const arrayUnidades = (this.model.array_unidades || '').split(';');
                const arrayLotes = (this.model.array_lotes || '').split(';');
                const arrayMarcas = (this.model.array_marcas || '').split(';');
                const idsTipos = (this.model.ids_tipos || '').split(';');
                const arrayEnvases = (this.model.array_envases || '').split(';');
                const arrayLotesConselleria = (this.model.array_lotes_conselleria || '').split(';');
                
                let unidadesMedida: string[] = [];
                if (this.showStockAdditionalFields) {
                    unidadesMedida = (this.model.unidades_medida || '').split(';');   
                }

                for (const i of [1, 2, 3, 4, 5]) {
                    this.modelLinea['id_producto' + i] = idsProductos[i - 1] || '';
                    this.modelLinea['unidades' + i] = arrayUnidades[i - 1] || '';
                    this.modelLinea['lote' + i] = arrayLotes[i - 1] || '';
                    this.modelLinea['marca_comercial' + i] = arrayMarcas[i - 1] || '';

                    if (this.showStockAdditionalFields) {
                        this.modelLinea['unidad_medida' + i] = unidadesMedida[i - 1] || '';

                    }

                    if (this.showStockTipoDeDato) {
                        this.modelLinea['id_tipo' + i] = idsTipos[i - 1] || '';

                        if (this.appName === 'pyf') {

                            if (i === 1 && this.model.id_variedad != null) {
                                this.modelLinea.id_variedad = this.model.id_variedad;
                            }

                            if (i === 2 && this.model.id_variedad2 != null) {
                                this.modelLinea.id_variedad2 = this.model.id_variedad2;
                            }

                            if (i === 3 && this.model.id_variedad3 != null) {
                                this.modelLinea.id_variedad3 = this.model.id_variedad3;
                            }

                            if (i === 1 && this.model.tipo_semilla != null) {
                                this.modelLinea.tipo_semilla = this.model.tipo_semilla;
                            }

                            if (i === 2 && this.model.tipo_semilla2 != null) {
                                this.modelLinea.tipo_semilla2 = this.model.tipo_semilla2;
                            }

                            if (i === 3 && this.model.tipo_semilla3 != null) {
                                this.modelLinea.tipo_semilla3 = this.model.tipo_semilla3;
                            }

                        } else if (this.appName === 'bravosol') {
                            this.modelLinea['lote_conselleria' + i] = arrayLotesConselleria[i - 1] || '';
                        }
                    }

                    if (this.showStockEnvase) {
                        this.modelLinea['envase' + i] = arrayEnvases[i - 1] || '';
                    }
                }

                if (this.appName === 'covidai') {
                    this.modelLinea.lote1_conselleria = this.model.lote1_conselleria ? this.model.lote1_conselleria : '';
                    this.modelLinea.lote1 = this.model.lote1 ? this.model.lote1 : '';
                }

                if (this.showStockTipoDeDato && this.model.tipo_dato) {
                    this.formatTipos();
                }
            }

            this.changeLabelCliPro();

            this.loadingData = false;
            resolve(true);
        });

        this.formRequest.beforeSend(async resolve => {

            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.fincas.selected as FincasModel || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {}).id;
            const userIdFromFinca = (this.fincas.selected as FincasModel || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';


            if (!this.model.fecha) {
                this.model.fecha = moment().toDate().toString();
            }

            if (!this.model.tipo) {
                this.model.tipo = 'E';
            }


            this.model.fecha = Utils.formatDate(this.model.fecha ?? '');
            //
            // Revisar si esto hace falta para otros clientes que tengan fecha injerto
            //
            if (this.appName === 'covidai') {
                if (!this.model.fecha_injerto) {
                    this.model.fecha_injerto = moment().toDate().toString();
                }
                this.model.fecha_injerto = Utils.formatDate(this.model.fecha_injerto ?? '');
            }
            //
            // Fin revision
            //

            //
            // Fin revision
            //
            this.model.ids_productos = this.model.array_unidades = this.model.array_lotes = this.model.array_marcas = '';

            if (this.showStockTipoDeDato) {
                this.model.ids_tipos = '';
            }

            if (this.showStockEnvase) {
                this.model.array_envases = '';
            }

            if (this.showStockAdditionalFields) {
                this.model.unidades_medida = '';
            }

            Object.keys(this.modelLinea).forEach(key => {
                if (key.includes('id_producto')) { this.model.ids_productos += this.modelLinea[key] + ';'; }
                if (key.includes('unidades')) { this.model.array_unidades += this.modelLinea[key] + ';'; }
                if (key.includes('lote')) { this.model.array_lotes += this.modelLinea[key] + ';'; }
                if (key.includes('marca_comercial')) { this.model.array_marcas += this.modelLinea[key] + ';'; }
                if (key.includes('id_tipo')) { this.model.ids_tipos += this.modelLinea[key] + ';'; }
                if (key.includes('envase')) { this.model.array_envases += this.modelLinea[key] + ';'; }
                if (this.appName === 'bravosol' && key.includes('lote_conselleria')) {
                    this.model.array_lotes_conselleria += this.modelLinea[key] + ';';
                }
                if (this.showStockAdditionalFields && key.includes('unidad_medida')) {
                    this.model.unidades_medida += this.modelLinea[key] + ';';
                }
            });

            this.model.ids_productos = this.model.ids_productos.substring(0, (this.model.ids_productos || '').length - 1);
            this.model.array_unidades = this.model.array_unidades.substring(0, (this.model.array_unidades || '').length - 1);
            this.model.array_lotes = this.model.array_lotes.substring(0, (this.model.array_lotes || '').length - 1);
            this.model.array_marcas = this.model.array_marcas.substring(0, (this.model.array_marcas || '').length - 1);

            if (this.appName === 'bravosol' || this.appName === 'covidai') {
            } else {
                delete this.model.array_lotes_conselleria;
            }

            this.model.id_variedad = this.modelLinea.id_variedad ?? '';
            this.model.tipo_semilla = this.modelLinea.tipo_semilla ?? '';

            if (this.showStockEnvase) {
                this.model.array_envases = 
                    this.model.array_envases?.substring(0, (this.model.array_envases || '').length - 1) ?? '';
                this.model.array_envases = this.model.array_envases ?? '';
            }

            if (this.showStockTipoDeDato) {
                this.model.ids_tipos = this.model.ids_tipos?.substring(0, (this.model.ids_tipos || '').length - 1) ?? '';
                this.model.ids_productos = this.model.ids_tipos;
            }

            if (this.appName === 'pyf') {
                this.model.id_variedad2 = this.modelLinea.id_variedad2 ?? '';
                this.model.id_variedad3 = this.modelLinea.id_variedad3 ?? '';
                this.model.tipo_semilla2 = this.modelLinea.tipo_semilla2 ?? '';
                this.model.tipo_semilla3 = this.modelLinea.tipo_semilla3 ?? '';
            }
            // Déspues del bucle for
            if (this.appName === 'covidai') {
                this.model.lote1 = '';
                this.model.lote1_conselleria = this.modelLinea.lote1_conselleria ? this.modelLinea.lote1_conselleria : '';
                this.model.lote1 = this.modelLinea.lote1 ? this.modelLinea.lote1 : '';
            }
            if (this.appName === 'bravosol' || this.appName === 'covidai') {
                this.model.array_lotes = '';
                this.model.array_lotes_conselleria = '';

                Object.keys(this.modelLinea).forEach(key => {
                    //if (key.includes('lote')) { this.model.array_lotes += this.modelLinea[key] + ';'; }
                    if (key === 'lote1') {
                        this.model.array_lotes += this.modelLinea[key] ? this.modelLinea[key] + ';' : ';';
                    }
                    if (key === 'lote2') {
                        this.model.array_lotes += this.modelLinea[key] ? this.modelLinea[key] + ';' : ';';
                    }
                    if (key === 'lote3') {
                        this.model.array_lotes += this.modelLinea[key] ? this.modelLinea[key] + ';' : ';';
                    }
                    if (this.appName === 'bravosol' && key.includes('lote_conselleria')) {
                        this.model.array_lotes_conselleria += this.modelLinea[key] ? this.modelLinea[key] + ';' : ';';
                    }

                    if (this.appName === 'covidai' && key.includes('lote1_conselleria')) {
                        this.model.array_lotes_conselleria += this.modelLinea[key] ? this.modelLinea[key] + ';' : ';';
                    }
                });
                this.model.array_lotes_conselleria =
                    this.model.array_lotes_conselleria.substring(0, (this.model.array_lotes_conselleria || '').length - 1);
                this.model.array_lotes = this.model.array_lotes.substring(0, (this.model.array_lotes || '').length - 1);
            }



            if (this.faltanUnidades) {

                resolve(null);

            } else {
                setTimeout(() => {
                    this.goBack();
                }, 1000);
            }

            resolve(true);
        });
    }
}// class;