import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { FormCommonApiService } from 'src/app/service/api/formcommon-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { BaseForm } from 'src/app/view/base-form';
import { ProductosGanaderiaFormDefinition } from '../form-control/productos-ganaderia-form-definition';

import type { OnInit, OnDestroy } from '@angular/core';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Component({
    selector: 'app-producto-ganaderia-form',
    templateUrl: './producto-ganaderia-form.component.html',
    styleUrls: ['./producto-ganaderia-form.component.scss']
})
export class ProductoGanaderiaFormComponent extends BaseForm implements OnInit, OnDestroy {

    public override model: ProductosModel = {nombre: '', materia_activa: ''};
    public formRequest: AppFormRequest = new AppFormRequest();
    public form: ProductosGanaderiaFormDefinition = new ProductosGanaderiaFormDefinition();  

    constructor(public override route: ActivatedRoute,
        public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public productApi: ProductosApiService,
        public override router: Router) {
        super(
            route,
            router,
            dashboardEvents,
            productApi.productos.PUT,
            productApi.productos.POST,
            'productos-ganaderia',
            'Actualizar producto de ganadería',
            'Crear producto de ganadería'
        );
    }

    ngOnInit() {
        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.productApi.productos.POST)
            .setPutRequest(this.productApi.productos.PUT)
            .setGetRequest(this.productApi.productos.GET)
            .setFormFields(
                this.form.topFormFields
                    .concat(this.form.bottomFormFieldsLeft)
                    .concat(this.form.bottomFormFieldsRight)
                    .concat(this.form.baselineFormFields)
            )
            .setFieldsToSend(
                (['id', 'id_cliente']).concat(
                    this.form.topFormFields.map(it => it.field ?? ''),
                    this.form.bottomFormFieldsLeft.map(it => it.field ?? ''),
                    this.form.bottomFormFieldsRight.map(it => it.field ?? ''),
                    this.form.baselineFormFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    submit() {
        this.formRequest.beforeSend(resolve => {
            this.model.tipo = 'ganaderia';
            resolve(true);
        });

        this.formRequest.send();
    }

    public override goBack() {
        this.router.navigate(['dashboard', this.pathToGoBack]);
    }  

}
