import { features } from './features';

features.applicationType = 'fitosgest';
features.showAnalisis = true;
features.showAnalisisNumeroAnalisis = true;
features.showCargaPAC = true;
features.showMotivoFito = true;
features.showVisitasConsultas = true;
features.showDesplazamientoObra = true;
features.empresaName = 'ProSanzCu';
features.showPlantas = false;
features.showPrecio = false;
features.showNPK = false;
features.showPartida = true;
features.puedeImprimir = true;
features.showRecetaTratamiento = true;
features.showEnviarCorreo = true;
features.showKilosHectarea = true;
features.showCuadernoPorSector = true;
features.clientesMostrarTodosDefault = true;
features.filterAplicadoresByClient = true;
features.showTareasSiembra = true;
features.showTareasSiembraProductos = true;
features.showTareasFertilizantesTipoFertilizacion = true;
features.showVentusky = true;
features.activateSIEX = false;

export const environment = {
    production: true,
    v: '1',
    serverUrl: 'https://app-prosanzcu.com/',
    logoUrl: 'assets/logo_prosanzcu.png',
    appName: 'prosanzcu',
    features: features
};