import { environment } from './../../../../../environments/environment';
import { Component } from '@angular/core';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { Router } from '@angular/router';
import { FincasApiService } from '../../../../service/api/fincas-api.service';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import {
    InputType
} from '../../../../common/components/form-builder/form-builder.component';
import { BaseView } from '../../../base-view';
import { build, list, stack } from '../../../../common/classes/request-builder';
import { Filtering } from '../../../../service/filtering/filtering';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
import { RequestButtonTypes } from '../../../../common/components/request-button/request-button.component';
import { StorageManager } from '../../../../common/storage-manager.class';
import { FileUploadModule } from 'primeng/fileupload';
import { Utils } from '../../../../common/utils';
import { FincasService } from '../../../../service/fincas/fincas.service';
import { createCsv } from 'src/app/common/createCsv';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
@Component({
    selector: 'app-finca',
    templateUrl: './finca.component.html',
    styleUrls: ['./finca.component.scss']
})
export class FincaComponent extends BaseView implements OnInit {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public serverUrl = environment.serverUrl;
    public rol: string = StorageManager.getUser().rol;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (this.rol === 'admin'));
    public clienteName = environment.features.clienteName;
    public fincaName = environment.features.fincaName;
    public showNumeroFincas = environment.features.showNumeroFincas;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public showCargaPAC = environment.features.showCargaPAC;
    public showCuadernoDeCampo = environment.features.showCuadernoDeCampo;
    public cuadernoOnlyByYear = environment.features.cuadernoOnlyByYear;
    public showCuadernoPorSector = environment.features.showCuadernoPorSector;
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public showFincasRazonSocial = environment.features.showFincasRazonSocial;
    public showTipoExplotacion = environment.features.showTipoExplotacion;
    public showFincasCodigo = environment.features.showFincasCodigo;
    public showFincasInformeTratamientos = environment.features.showFincasInformeTratamientos;
    public showFincasLibroFertilizantes = environment.features.showFincasLibroFertilizantes;
    public showFincasGenGml = environment.features.showFincasGenGml;
    public showTareasMto = environment.features.showTareasMto
    public showFincasCsv = environment.features.showFincasCSV;

    public FileUpload: FileUploadModule = new FileUploadModule();
    public getRequest: RequestHandler = this.fincasApi.fincasGET;
    public deleteRequest: RequestHandler = this.fincasApi.fincasDELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;
    public canDuplicateFinca = environment.features.canDuplicateFinca;

    public menuItemId = 'finca';
    public filter: Filtering<any> = new Filtering<any>();

    public sectores = list();
    public parcelas = list();
    public variedades = list();


    /* lista de años por defecto */
    public year = list();

    public deleteWarningMessage: string = '';
    public deleteWarning: string = '';

    public parcelasAux: any = null;

    public showModalPAC = false;
    public showPACError = true;
    public txtPACError = '';
    public txtPACInfo = '';
    public fincaPAC = '';
    public urlUpload = '';
    public enableUpload = true;
    public cuadernoColumns = 3;

    public generarFields: ItemInterface<any>[] = [
        {
            field: 'id_sector',
            label: this.sectorName,
            placeholder: 'TODOS',
            inputType: { type: InputType.DROPDOWN },
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (sector: { value: { id_finca: any; }; }) => sector.value.id_finca === this.model.id
        },
        {
            field: 'id_variedad',
            label: 'Variedad',
            placeholder: 'TODAS',
            inputType: {
                type: this.appName === 'covidai' ? InputType.MULTISELECT : InputType.DROPDOWN
            },
            values: this.parcelas,
            multiSelect: this.appName === 'covidai',
            valuePrimaryKey: 'id',
            filter: (it: { value: { id_sector: any; id_finca: any; }; }) => {
                this.parcelas.values = this.parcelas.values.filter((parcela, index, self) =>
                    index === self.findIndex((t) => (
                        t.value.id_variedad === parcela.value.id_variedad
                    ))
                );

                const cond1 = this.sectores.selected ? (it.value.id_sector === this.sectores.selected.id) : true;
                const cond2 = it.value.id_finca === this.model.id;
                // let variedad = this.parcelas.values.findIndex(parcela => {
                //   return parcela.value.id_variedad == it.value.id_variedad;
                // });

                return cond1 && cond2;
            }
        },
        {
            field: 'fecha_inicio',
            label: 'Fecha inicio',
            inputType: { type: InputType.CALENDAR }
        },
        {
            field: 'fecha_fin',
            label: 'Fecha fin',
            inputType: { type: InputType.CALENDAR }
        }
    ];

    public formFields: ItemInterface<any>[] = [
        {
            field: 'cliente_nombre',
            label: 'Cliente',
            inputType: {type: InputType.TEXT},
            visible: this.hasClients
        },
        {
            field: 'razon_social',
            name: 'Razón Social',
            label: 'Razón social',
            inputType: { type: InputType.TEXT },
            visible: this.showFincasRazonSocial
        },
        {
            field: 'nombre',
            name: 'Nombre de' + this.fincaName.toLowerCase(),
            label: 'Nombre de ' + this.fincaName.toLowerCase(),
            inputType: { type: InputType.TEXT }
        },
        { field: 'nif', name: 'NIF', label: 'NIF', inputType: { type: InputType.TEXT } },
        {
            field: 'codigo',
            name: 'Código',
            label: 'Código',
            inputType: { type: InputType.TEXT },
            visible: this.showFincasCodigo
        },
        {
            field: 'registro_nacional',
            name: 'Registro Nacional',
            label: 'Registro Nacional',
            inputType: { type: InputType.TEXT },
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'registro_autonomico',
            name: 'Registro Autonómico',
            label: 'Registro Autonómico',
            inputType: { type: InputType.TEXT },
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'direccion',
            name: 'Dirección',
            label: 'Dirección',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'localidad',
            name: 'Localidad',
            label: 'Localidad',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'codigo_postal',
            name: 'Código Postal',
            label: 'Código Postal',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'provincia',
            name: 'Provincia',
            label: 'Provincia',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'telefono',
            name: 'Teléfono',
            label: 'Teléfono',
            inputType: { type: InputType.TEXT }
        },
        { field: 'movil', name: 'Móvil', label: 'Móvil', inputType: { type: InputType.TEXT } },
        { field: 'email', name: 'Email', label: 'Email', inputType: { type: InputType.TEXT } },
        {
            field: 'lectura_contador', name: 'Acumulado m3', label: 'Acumulado m3', inputType: { type: InputType.TEXT },
            visible: this.appName === 'abonosfolques'
        },
        {
            field: 'superficie',
            name: 'Superficie',
            label: 'Superficie',
            inputType: { type: InputType.TEXT }
        },

        {
            field: 'tipo_explotacion',
            name: 'Tipo de Explotación',
            label: 'Tipo de explotación',
            inputType: { type: InputType.TEXT },
            visible: this.showTipoExplotacion,
            transform: [
                this.changeTipoExplotacion.bind(this)
            ]
        },
        {
            field: 'alquilado',
            name: 'Alquilado',
            label: 'Alquilado',
            inputType: { type: InputType.RO_SWITCH },
            visible: this.appName === 'covidai' ? true : false
        },
        {
            field: 'observaciones',
            name: 'Observaciones Cliente',
            label: 'Observaciones Cliente',
            inputType: { type: InputType.TEXTAREA },
            visible: this.appName === 'gardengest'
        },
        {
            field: 'propiedad',
            name: 'Propiedad',
            label: 'Propiedad',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'ccc',
            name: 'CCC',
            label: 'CCC',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'campanaragricola'
        }
    ];

    public formFieldsTitular: ItemInterface<any>[] = [
        { field: 'titular_nombre', name: 'Titular Nombre', label: 'Titular Nombre', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_nif', name: 'Titular NIF', label: 'Titular NIF', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_direccion', name: 'Titular Dirección', label: 'Titular Dirección', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_localidad', name: 'Titular Localidad', label: 'Titular Localidad', inputType: { type: InputType.EDIT_TEXT } },
        {
            field: 'titular_codigo_postal',
            name: 'Titular Código Postal',
            label: 'Titular Código Postal',
            inputType: { type: InputType.EDIT_TEXT }
        },
        { field: 'titular_provincia', name: 'Titular Provincia', label: 'Titular Provincia', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_telefono', name: 'Titular Teléfono', label: 'Titular Teléfono', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_email', name: 'Titular Email', label: 'Titular Email', inputType: { type: InputType.EDIT_TEXT } },
        { field: 'titular_tipo', name: 'Tipo Representación', label: 'Tipo Representación', inputType: { type: InputType.EDIT_TEXT } },
    ];

    public cols = [
        { field: 'id', header: 'Nº Finca', filter: '', width: '140px', visible: this.showNumeroFincas },
        {
            field: 'cliente_nombre',
            header: this.clienteName,
            filter: '',
            width: '200px',
            visible: this.hasClients
        },
        { field: 'nombre', header: 'Nombre ' + this.fincaName, filter: '', width: '200px' },
        { field: 'provincia', header: 'Provincia', filter: '' },
        { field: 'titular_nombre', header: 'Titular', filter: '', visible: this.appName !== 'gardengest' },
        { field: 'telefono', header: 'Teléfono', filter: '' },
        { field: 'email', header: 'Email', filter: '', width: '150px' },
        {
            field: 'lectura_contador',
            header: 'Acumulado m3 ',
            visible: this.appName === 'abonosfolques'
        },
        { field: 'cooperativa', header: 'Cooperativa', filter: '', width: '150px', visible: this.showClientesCooperativa }
    ];

    public user = StorageManager.getUser();

    public canAddFincas = environment.features.canAddFincas;
    public canEditFincas = environment.features.canEditFincas;
    public canDeleteFincas = environment.features.canDeleteFincas;

    /* AÑO ACTUAL */
    private currentDate = new Date();
    private currentYear: number = this.currentDate.getFullYear();

    /* número de años a mostrar en el desplegable del Cuaderno de campo */
    private yearsToShow = this.currentYear - 2016;


    constructor(
        private fincasApi: FincasApiService,
        private _fincasService: FincasService,
        private formApi: FormCommonApiService,
        public dashboard: DashboardService,
        private router: Router
    ) {
        super(dashboard, fincasApi.fincasGET, fincasApi.fincasDELETE);

    }


    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        stack([
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.parcelas, ((it: { variedad: any; cultivo: string; }) => (it.variedad || 'Sin variedad') + ' (' + it.cultivo + ')'), true),
            build(this.formApi.variedadesGET, this.variedades, 'variedad', true),
        ]).then(() => {
            this.filterVariedades();
            this.filter.filter();
        });

        this.filter.addFilter((data: any) => (data || []).filter((finca: { id: any; }) => finca && finca.id));
    }

    public changeTipoExplotacion(model: any) {

        const tipos = list(
            ['...', null],
            ['Agricultura Ecológica', 'AE'],
            ['Producción Integrada', 'PI'],
            ['Certificacion Privada', 'CP'],
            ['Agrupacion de Tratamiento Integrado en Agricultura', 'Atrias'],
            ['Asistida de un asesor', 'AS'],
            ['Sin obligacion de aplicar la GIP', 'NO']
        );
        try {
            if (model.tipo_explotacion == null) {
                return model;
            }
            const tipoExplotacion = tipos.filtered.find(tipo => {
                if ((model['tipo_explotacion'] as string).localeCompare(tipo.value) === 0) {
                    return tipo;
                }
            });

            model['tipo_explotacion'] = tipoExplotacion.label;
            return model;
        } catch (error) {
            return model;
        }
    }

    public filterSectores() {
        this.sectores.selected = null;
        this.parcelas.selected = null;
        this.variedades.selected = null;
    }

    public filterVariedades() {
        const idx = [];
        const repes: string[] = [];
        this.parcelas.values = this.parcelas.values.map(it => {
            if (it.value && !repes.includes(it.label + '_' + it.value.id_finca + '_' + it.value.id_sector)) {
                repes.push(it.label + '_' + it.value.id_finca + '_' + it.value.id_sector);
                idx.push(it);
                return it;
            }
            return null;
        }).filter(it => it);
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-finca']);
        }
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-finca', data.id]);
    }

    public duplicate(data: any) {
        this.router.navigate(['dashboard', 'duplicar-finca', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            if (this.deleteWarning) {
                this.deleteWarning = '';
                this.deleteWarningMessage = '';

                this.deleteRequest.safePerform(this.model);
                this.deleteRequest.response(() => {
                    if (!this.deleteRequest.hasError) {
                        this.getRequest.safePerform();
                    }
                });
            } else {
                this.deleteWarningMessage =
                    'Se borrará toda la información asociada';
                this.deleteWarning = '¡' + this.fincaName + ' con datos! ¿Continuar?';
            }
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }


    public globalVisualTransform(value: string) {
        return value;
    }

    public globalConditionalStyle(value: string | number, col: ColInterface): ColStylesInterface {
        if (col.field === 'lectura_contador' && +value >= 6000) {
            return {
                textAlign: 'center',
                marginRight: '60px',
                color: 'red'
            };
        }
        if (Utils.isNumber(value?.toString() ?? '') || col.field === 'telefono') {
            return {
                textAlign: 'center',
                marginRight: '60px'
            };
        }
        if (col.field === 'provincia' || col.field === 'titular_nombre') {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }


    ////////////////////////////////////////////////////////////
    ////     SUBE ARCHIVIO PAC Y CREA/ACTUALIZA PARCELAS    ////
    ////////////////////////////////////////////////////////////
    checkFile(event: { files: { name: any; }[]; }) {
        this.showPACError = false;
        const file = event.files[0]?.name;
        if (!file.includes('.xlsx') && !file.includes('.xls')) {
            this.showPACError = true;
            this.txtPACError = 'El archivo debe ser un Excel(xlsx)';
            //this.enableUpload = false;
        }
    }

    uploadDialog(data: any) {
        this.showModalPAC = true;
        this.fincaPAC = data.id;
        this.urlUpload = environment.serverUrl +
            'ws/informes/excel_pac/fileUploadData.php?id_finca=' +
            data.id +
            '&id_cliente=' +
            data.id_cliente +
            '&id_usuario=' +
            data.id_usuario;
    }

    myUploader(event: { files: { name: string | string[]; }; }) {
        if (event.files.name.indexOf('.xlsx') || event.files.name.indexOf('.xls')) {
            console.log('CORRECTO');
        }
        else { console.log('EXTENSION INCORRECTA'); }
    }

    uploaded(event: any) {
        const response = event.originalEvent.body;

        const parcelasFound = response.content_found;
        const parcelasNotFound = response.content_not_found;
        const especiesFound = response.especie_found;
        const especiesNotFound = response.especie_not_found;

        const arrayEspeciesFound = [];
        const arrayEspeciesNotFound: any[] = [];
        const arrayParcelasFound: string | any[] = [];
        const arrayParcelasNotFound: string | any[] = [];

        especiesFound.forEach((it: any) => {
            arrayEspeciesFound.push(it);
        });
        especiesNotFound.forEach((it: any) => {
            arrayEspeciesNotFound.push(it);
        });
        parcelasFound.forEach((it: { numero: any; }) => {
            arrayParcelasFound.push(it.numero);
        });
        parcelasNotFound.forEach((it: { numero: any; }) => {
            arrayParcelasNotFound.push(it.numero);
        });

        this.showPACError = true;
        this.txtPACInfo = 'Carga de las Parcelas realizada correctamente<br />';
        this.txtPACInfo += arrayParcelasNotFound;
        this.txtPACInfo += '<br>';

        this.txtPACInfo += 'Parcelas actualizadas<br />';
        this.txtPACInfo += arrayParcelasFound;
        this.txtPACInfo += '<br/>';
        this.txtPACInfo += '<br/>';

        if (!arrayEspeciesNotFound.length) {
            this.txtPACInfo += '¡Todos los Especies/Cultivos han sido encontrados! <br />';
        } else {
            this.txtPACError = '¡Especies/Cultivos no encontrados! <br />';
            this.txtPACError += this.unique(arrayEspeciesNotFound);
        }
    }

    closeFUDialog() {
        this.showModalPAC = false;
        this.showPACError = false;
        location.reload();
    }

    unique(array: any[]) {
        return array.filter(function (a: number) {
            return !array[a] ? (array[a] = true) : false;
        }, {});
    }

    /* Generamos la lista de años desde el 2016 hasta el año actual */
    public generateYearList() {
        this.year.filtered[0] = { 'label': 'Seleccione un año', 'value': null };
        for (let i = this.yearsToShow; i >= 0; i--) {
            this.year.filtered.push({ 'label': this.currentYear - i, 'value': this.currentYear - i });
        }
    }

    // PETICIÓN GET PARA GENERAR CUADERNO 
    public generarCuaderno(informe_tratamientos = false, libro_fertilizantes = false) {
        if (!this.model.fecha_inicio || !this.model.fecha_fin) {
            alert('Debe especificar un intervalo de fechas.');
            return;
        }

        const finca = this.model.nombre;
        const idFinca = this.model.id;
        const idCliente = this.model.id_cliente;
        const appName = this.appName;
        const idSectores = (this.sectores.selected == null) ? '' : this.sectores.selected.id;
        let idVariedad = '';
        const user = this.user.id;

        if (this.appName === 'covidai') {
            this.parcelas.selected.forEach((parcela: { id_variedad: string; }) => {
                idVariedad += parcela.id_variedad + ',';
            });
            idVariedad = idVariedad.slice(0, -1);
        } else {
            idVariedad = (this.parcelas.selected == null) ? '' : this.parcelas.selected.id_variedad;
        }// if();

        let cuadernoFields = '?finca=' + finca +
            '&id_finca=' + idFinca +
            '&id_sector=' + idSectores +
            '&id_cliente=' + idCliente +
            '&app_name=' + appName +
            '&id_variedad=' + idVariedad +
            '&id_user=' + user;

        const interval: any = this.getDates(this.model.fecha_inicio, this.model.fecha_fin);
        const fechaFin = interval['fechaFin'];
        const fechaInicio = interval['fechaInicio'];
        const anyo2 = interval['anyo'];
        cuadernoFields += '&fecha_fin=' + fechaFin +
            '&fecha_inicio=' + fechaInicio + '&anyo=' + anyo2;

        if (informe_tratamientos) {
            cuadernoFields += '&informe_tratamientos=true';
        }
        if (libro_fertilizantes) {
            cuadernoFields += '&libro_fertilizantes=true';
        }
        window.open((this.serverUrl + 'ws/informes/index.php' + cuadernoFields), '_blank');
    }

    public formChanges() {
    }

    public generaGML(data: { id: any; }) {

        this._fincasService.verificaGML(data.id).toPromise()
            .then((resp: any) => {
                if (resp.success) { this._fincasService.generaGML(data.id); }
                else { alert(resp.mensaje); }
            })
            .catch(error => {
                console.log('error', error);
            });

    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields,
            this.formFieldsTitular
        ];


        const csvContent = createCsv(arrayFormFields, this.filter.value, 'titular_tipo');

        const csvLink: any = document.getElementById('csvLink');
        csvLink.download = 'Fincas.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }// ();

    public getDates(inicio: string | Date, fin: string | Date): {} {
        const fechaInicio = (inicio instanceof Date) ? Utils.formatDate(inicio) : inicio;
        const fechaFin = (fin instanceof Date) ? Utils.formatDate(fin) : fin;

        // Si no hay año se calcula con los años de la fecha de inicio y de fin (2017-2018)
        let anyo = fechaInicio.split('/')[2];
        anyo = fechaInicio.split('/')[2] + '-' + fechaFin.split('/')[2];
        if (fechaInicio.split('/')[2] === fechaFin.split('/')[2]) { anyo = fechaInicio.split('/')[2]; }

        return { fechaInicio, fechaFin, anyo };
    }
}