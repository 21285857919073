import { createCsv } from 'src/app/common/createCsv';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import { Component } from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';
const TRUTHY_VALUES = [1, '1', true, 'true'];

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaPiscinasModel } from 'src/app/models/tareas/tarea-piscinas.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
@Component({
    selector: 'app-tareas-mto-piscinas',
    templateUrl: './tareas-mto-piscinas.component.html',
    styleUrls: ['./tareas-mto-piscinas.component.scss']
})
export class TareasMtoPiscinasComponent extends BaseView implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public serverUrl = environment.serverUrl;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     

    public getRequest: RequestHandler = this.tareasApi.mtoPiscinas.GET;
    public deleteRequest: RequestHandler = this.tareasApi.mtoPiscinas.DELETE;
    public pathToForm = 'crear-piscina';  
    public menuItemId = 'piscinas';  

    public override filtering: Filtering<TareaPiscinasModel> = new Filtering<TareaPiscinasModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaPiscinasModel = {};
  
    public distributionOrder = [3];

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_inicio', 
                name: 'Hora Inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_fin', 
                name: 'Hora Fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'horas_totales', 
                name: 'Horas', 
                label: 'Horas', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'finca', 
                name: this.fincaName, 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'mantenimiento', 
                name: 'Mantenimiento/Creación', 
                label: 'Mantenimiento', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.TEXT}
            }
        ];
    
    public formFieldsTareas01: ItemInterface<object>[] = 
        [
            {
                field: 'barredera', 
                name: 'Barrendera', 
                label: 'Barredera', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'cepillado', 
                name: 'Cepillado Paredes/Fondo', 
                label: 'Cepillado Paredes/Fondo', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_red', 
                name: 'Limpieza con Red', 
                label: 'Limpieza con Red', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'rascado_linea_flotacion', 
                name: 'Rascado Línea Flotación', 
                label: 'Rascado Línea Flotación', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsTareas02: ItemInterface<object>[] =
        [
            {
                field: 'comprobar_parametros_ph', 
                name: 'Ph', 
                label: 'Ph', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_cloro', 
                name: 'Cloro', 
                label: 'Cloro', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_acido_isocianurico', 
                name: 'Acido Isocianurico', 
                label: 'Acido Isocianurico', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_alcalinidad', 
                name: 'Alcalinidad', 
                label: 'Alcalinidad', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_fosfatos', 
                name: 'Fosfatos', 
                label: 'Fosfatos', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'comprobar_parametros_observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTareas03: ItemInterface<object>[] =
        [
            {
                field: 'lavado_enjuague_cesti', 
                name: 'Lavado/Enjuague/Cesto Bomba', 
                label: 'Lavado/Enjuague/Cesto Bomba', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_duchas', 
                name: 'Limpieza Duchas', 
                label: 'Limpieza Duchas', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_skimmer', 
                name: 'Limpieza Skimmer', 
                label: 'Limpieza Skimmer', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'nivel_vaso', 
                name: 'Nivel del Vaso', 
                label: 'Nivel del Vaso', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_cuarto_depuradora', 
                name: 'Limpieza Cuarto Depuradora', 
                label: 'Limpieza Cuarto Depuradora', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsProductos01: ItemInterface<object>[] = 
        [ 
            {
                field: 'cloro_liquido', 
                name: '[Cloro] Líquido', 
                label: 'Líquido', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cloro_granulado', 
                name: '[Cloro] Granulado', 
                label: 'Granulado', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cloro_pastillas', 
                name: '[Cloro] Pastillas', 
                label: '[Cloro] Pastillas', 
                inputType: {type: InputType.TEXT}
            },
        ]; 

    public formFieldsProductos02: ItemInterface<object>[] = 
        [ 
            {
                field: 'floculante_liquido', 
                name: '[Floculante] Líquido', 
                label: '[Floculante] Líquido', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'floculante_pastillas', 
                name: '[Floculante] Pastillas', 
                label: '[Floculante] Pastillas', 
                inputType: {type: InputType.TEXT}
            },
        ]; 

    public formFieldsProductos03: ItemInterface<object>[] = 
        [ 
            {
                field: 'coagulante', 
                name: 'Coagulante', 
                label: 'Coagulante', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'algicida', 
                name: 'Algicida', 
                label: 'Algicida', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'alcalinidad', 
                name: 'Alcalinidad', 
                label: 'Alcalinidad', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'ph_minus', 
                name: 'PH-', 
                label: 'PH-', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'ph_plus', 
                name: 'PH+', 
                label: 'PH+', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'activador', 
                name: 'Activador', 
                label: 'Activador', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'enzimatic', 
                name: 'Enzimatic', 
                label: 'Enzimatic', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'desincrustante', 
                name: 'Desincrustante', 
                label: 'Desincrustante', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'eliminador_fosfatos', 
                name: 'Eliminador Fosfatos', 
                label: 'Eliminador Fosfatos', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsRecambios: ItemInterface<object>[] = 
        [      
            {
                field: 'arena_silice', 
                name: 'Arena Sílice (25kg)', 
                label: 'Arena Sílice (25kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'limpiador_silex', 
                name: 'Limpiador Silex', 
                label: 'Limpiador Silex', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'vidrio', 
                name: 'Vidrio (20kg)', 
                label: 'Vidrio (20kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sacos_sal', 
                name: 'Sacos Sal (25kg)', 
                label: 'Sacos Sal (25kg)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'limpiador_fotocelulas', 
                name: 'Limpiador Fotocelulas', 
                label: 'Limpiador Fotocelulas', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cesto_skimmer', 
                name: 'Cesto Skimmer', 
                label: 'Cesto Skimmer', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tapas_skimmer', 
                name: 'Tapas Skimmer', 
                label: 'Tapas Skimmer', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_sumidero', 
                name: 'Rejillas Sumidero', 
                label: 'Rejillas Sumidero', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'foco_luz', 
                name: 'Foco Luz', 
                label: 'Foco Luz', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'alcachofa_ducha', 
                name: 'Alcachofa Ducha', 
                label: 'Alcachofa Ducha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pomo_sencilla', 
                name: 'Pomo Sencilla', 
                label: 'Pomo Sencilla', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pomo_completo', 
                name: 'Pomo Completo', 
                label: 'Pomo Completo', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'red_piscina', 
                name: 'Red Piscina', 
                label: 'Red Piscina', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pertiga', 
                name: 'Pertiga', 
                label: 'Pertiga', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'bomba', 
                name: 'Bomba', 
                label: 'Bomba', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'cesto_bomba', 
                name: 'Cesto Bomba', 
                label: 'Cesto Bomba', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'escalera', 
                name: 'Escalera', 
                label: 'Escalera', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'rejillas_transversales', 
                name: 'Rejillas Transversales', 
                label: 'Rejillas Transversales', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'impulsores_boquilla', 
                name: 'Impulsores/Boquilla', 
                label: 'Impulsores/Boquilla', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'neat_clean', 
                name: 'Neat Clean', 
                label: 'Neat Clean', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificadores_orp', 
                name: 'Dosificadores ORP',  
                label: 'Dosificadores ORP', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificadores_ph', 
                name: 'Dosificadores PH', 
                label: 'Dosificadores PH', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sonda_orp', 
                name: 'Sonda ORP', 
                label: 'Sonda ORP', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'sonda_ph', 
                name: 'Sonda PH', 
                label: 'Sonda PH', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'dosificador_pastillas', 
                name: 'Dosificador Pastillas', 
                label: 'Dosificador Pastillas', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'manometro', 
                name: 'Manómetro', 
                label: 'Manómetro', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
    
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            }
        ];
  
    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];
    


    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora_inicio', header: 'Hora Inicio', filter: ''},
        {field: 'hora_fin', header: 'Hora Fin', filter: ''},
        {field: 'horas_totales', header: 'Horas', filter: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''}
    ];  

    public prioridad = {
        selected: ''
    };

    public totalHoras: Moment | string = '00:00';
    public auxTotalHoras: Moment | string = '00:00';
    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.mtoPiscinas.GET, tareasApi.mtoPiscinas.DELETE);
  
  
    }

    ngOnInit() {
    
        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }

        this.dashboard.setSubmenuItem(this.menuItemId);
        this.filtering.addFilter((copy: TareaPiscinasModel[]) => copy.filter(row => {
            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(row.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(row.prioridad ?? '');
            default:
                return true;
            }
        }));
        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TareaPiscinasModel[]) => copy.filter(row => {
          
                if (row.ids_trabajadores != null ) {
            
                    const idTrabajador = StorageManager.getUser().id;
                    const listaTrabajadores = (row.ids_trabajadores as string).split(';');
                    if ( listaTrabajadores.includes(idTrabajador)) {
                        return true;
                    }
            
                }
                return false;
            }));
        }
  
        /*** 
     *  Fin de filtrar por id_usuario = trabajador,
     *   
    */
        this.filtering.addFilter((copy: TareaPiscinasModel[]) => copy.filter((row: TareaPiscinasModel) => {
            if (this.fecha.inicio && this.fecha.fin && row.fecha) {
  
                const arrayFecha = row.fecha.toString().split('/');
                const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];
  
                if (
                    (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }));
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', this.pathToForm]);
        }
    }
  
    public edit(data: TareaPiscinasModel) {
        //console.log('data',data);
      
        this.router.navigate(['dashboard', 'editar-piscina', data.id]);
    }
  
    public duplicate(data: TareaPiscinasModel) {
        this.router.navigate(['dashboard', 'duplicar-piscina', data.id]);
    }  

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaPiscinasModel) {
        let x;
        this.formFields.forEach( form => {
            if ( form.field === 'mantenimiento' ){
                if (data.mantenimiento) {
                    form.label = 'Creación';
                } else {
                    form.label = 'Mantenimiento';
                }
            }
        });
        const trueFields = [
            'mantenimiento',
            'barredera',
            'cepillado',
            'limpieza_red',
            'rascado_linea_flotacion',
            'lavado_enjuague_cesti',
            'limpieza_duchas',
            'limpieza_skimmer',
            'nivel_vaso',
            'limpieza_cuarto_depuradora'
        ];
    
        trueFields.forEach( f => {
            data[f] = TRUTHY_VALUES.includes(data[f]?.toString() ?? '');
        });

        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if ( col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }
  
    public globalVisualTransform(value: string | number | boolean, col: ColInterface) {
        if (col.field === 'mantenimiento') {
            if ( TRUTHY_VALUES.includes(value) ) {
                return 'Creación';
            } else {
                return 'Mantemiento';
            }
        }
        return value;
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }

    getObservaciones( event: string ) {
        this.model.observaciones = event;
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsTareas01,
            this.formFieldsTareas02,
            this.formFieldsTareas03,
            this.formFieldsProductos01,
            this.formFieldsProductos02,
            this.formFieldsProductos03,
            this.formFieldsRecambios,
            this.formFieldsTrabajadores,
            this.formFieldsOrdenTrabajo,
            this.formFieldsObs,
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'observaciones');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Piscinas.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }

    public override updateFooter() {
        this.sum();
    }

    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {

            if ( tareas.horas_totales != null ) {
   
                if (tareas.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.horas_totales as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }

            if ( index + 1 >= this.filtering.value.length ){
  
                let minutos = moment(this.totalHoras).format('mm');
      
      
                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
      
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }

                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));

                this.totalHoras = differencia + ':' + minutos;
      
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }

            }
  
    
        });

        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filtering.filter();
    }

    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
  
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }

    ngAfterViewInit() {
        this.tareasApi.mtoPiscinas.GET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }

}
