import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { list } from '../../../../../common/classes/request-builder';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Component({
    selector: 'app-productos-simple-form',
    templateUrl: './productos-simple-form.component.html',
    styleUrls: ['./productos-simple-form.component.scss']
})
export class ProductosSimpleFormComponent extends BaseForm implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton: RequestButtonComponent = new RequestButtonComponent();

    public override model: ProductosModel = {nombre: '', materia_activa: ''};
    public udsMedida = list(['...', null], 'Litros', 'Kg');

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Nombre del producto', 
            field: 'nombre', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },
        {
            label: 'Nº Registro', 
            field: 'num_registro', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Materia Activa', 
            field: 'materia_activa', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Unidades de medida', 
            field: 'uds_medida', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.udsMedida
        }
    ];

    public formRequest: AppFormRequest = new AppFormRequest();
    public isProductSelected = false;
    public hasntRegisterNumber = false;
    public fecha_caducidad = '';

    constructor(public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public productosApi: ProductosApiService,
        public override router: Router,
        public override route: ActivatedRoute) {
        super(
            route,
            router,
            dashboardEvents,
            productosApi.productos.PUT,
            productosApi.productos.POST,
            'productos',
            'Actualizar Fitosanitario',
            'Crear Fitosanitario'
        );
    }

    ngOnInit() {
        // Eliminar duplicados v2
        // this.formApi.magramaProducto.response(productos => {
        //     for (const producto of productos) {
        //         const repes = productos.filter(it => it.referencia === producto.referencia) || [];
        //         if (repes.length > 1) {
        //             const index = productos.map(it => it.referencia).indexOf(repes[repes.length - 1].referencia);
        //             productos.splice(index, (repes.length - 1));
        //         }
        //     }
        // });

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.productosApi.productos.POST)
            .setPutRequest(this.productosApi.productos.PUT)
            .setGetRequest(this.productosApi.productos.GET)
            .setFormFields(Array.prototype
                .concat(
                    this.formFields
                )
            )
            .setFieldsToSend((['id'])
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public formChanges() {
        this.requestButton.error = '';
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public override goBack() {
        this.router.navigate(['dashboard', 'productos-s']);
    }

}
