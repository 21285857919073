<div id="main-content" class="custom-content" [class.nomargin]="!showMainMenu">
    <div class="content">
        <router-outlet></router-outlet>
    </div>
    <div class="logo">

    </div>
</div>

<p-dialog [header]="clientHeaderDialog" [(visible)]="showClientDialog" [modal]="true" [style]="{width: '40vw' ,height: '60vh'}"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw'}"
    [style.display]="(hasClients && userCanSeeClientes) ? 'initial' : 'none'" (onShow)="scrollToTop()" [closable]="false">


    <label for="">Seleccionar {{clienteName}}</label>
    <p-dropdown [options]="clientes.filtered" [(ngModel)]="clientes.selected" [placeholder]="'Seleccionar '+clienteName"
        optionValue="value" optionLabel="label" [filter]="true" [showClear]="true"
        (onChange)="updateClient()"></p-dropdown>

    <div class="d-flex justify-content-center mt-2">
        <button class="btn btn-primary" (click)="closeClientDialog();">
            Aceptar
        </button>
    </div>

</p-dialog>

<div *ngIf="showMainMenu" class="custom-navbar">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
                <a [routerLink]="['dashboard', 'inicio']">
                    <div [ngSwitch]="logoUrl">
                        <div *ngSwitchCase="'assets/logo_fitogest.png'" style="
                            position: relative;
                            top: 3px;
                            width: 152px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                                height: 45px;
                                padding: 7px;
                                margin-left: -4px;
                                margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_jobarma.png'" style="
                        position: relative;
                        top: 3px;
                        width: 140px;
                        height: 36px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;
                        background-color: white;
                        border-radius: 25px;">
                            <img style="
                            height: 45px;
                            padding: 7px;
                            margin-left: 2px;
                            margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_bravosol.png'" style="
                        position: relative;
                        top: 3px;
                        width: 130px;
                        height: 36px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;
                        background-color: white;
                        border-radius: 5px;">
                            <img style="
                            height: 45px;
                            padding: 7px;
                            margin-left: 2px;
                            margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_fitogesta3sdi.png'" style="
                                position: relative;
                                top: 3px;
                                width: 180px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 45px;
                    padding: 7px;
                    margin-left: -4px;
                    margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agrogest.png'" style="
                            position: relative;
                            top: 3px;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                        height: 45px;
                    padding: 7px;
                    margin-left: -4px;
                    margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agromainsa.png'" style="
                                position: relative;
                                top: 3px;
                                width: 148px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 39px;
                    padding: 7px;
                    margin-left: -1px;
                    margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_viverogest.png'" style="
                            position: relative;
                            top: 3px;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            text-align: center;
                            border-radius: 5px;">
                            <img style="
                        width: 120px;
                        height: 36px;
                    margin: 0;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_gestata.png'" style="
                            position: relative;
                            top: 3px;
                            width: 80px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white; 
                            text-align: center;
                            border-radius: 5px;">
                            <img style="
                        width: 80px;
                        height: 36px;
                    margin: 0;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_momparler.png'" style="
                            position: relative;
                            top: 3px;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            text-align: center;
                            border-radius: 5px;">
                            <img style="
                        width: 120px;
                        height: 36px;
                    margin: 0;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_malvesia.png'" style="
                            position: relative;
                            top: 3px;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            text-align: center;">
                            <img style="
                        width: 160px;
                        height: 36px;
                    margin: 0;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_gardengest.png'" style="
                            position: relative;
                            top: 3px;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            text-align: center;
                            border-radius: 25px;">
                            <img style="
                        width: 160px;
                        height: 36px;
                    margin: 0;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_covidai.png'" style="
                                position: relative;
                                top: 3px;
                                width:100px;
                                height: 36px;
                                margin: 6px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;">
                            <img style="
                    height: 36px;
                    margin-top: 0px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_viverosblanco.png'" style="
                                position: relative;
                                top: 3px;
                                width:100px;
                                height: 36px;
                                margin: 6px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 65px;
                    margin-top: -13px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_amoros.png'" style="
                                position: relative;
                                top: 3px;
                                width: 100px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 39px;
                    padding: 7px;
                    margin-left: -1px;
                    margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agricar.png'" style="
                        position: relative;
                        top: 3px;
                        width: 115px;
                        height: 36px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                          background-color: white;
                          height: 45px;
                          padding: 0px;
                          margin-left: 17px;
                          margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_torrepacheco.png'" style="
                        position: relative;
                        top: 3px;
                        width: 115px;
                        height: 36px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                          background-color: white;
                          height: 45px;
                          padding: 0px;
                          margin-left: 17px;
                          margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_almassora.png'" style="
                                position: relative;
                                top: 3px;
                                width: 100px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 39px;
                    padding: 7px;
                    margin-left: -1px;
                    margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_onda.png'" style="
                                position: relative;
                                top: 3px;
                                width: 110px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 40px;
                    padding: 2px;
                    margin-left: -1px;
                    margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_pyf.png'" style="
                                position: relative;
                                top: 3px;
                                width: 110px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 40px;
                    padding: 2px;
                    margin-left: -1px;
                    margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_villareal.png'" style="
                                position: relative;
                                top: 3px;
                                width: 75px;
                                height: 36px;
                                margin: 4px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                    height: 55px;
                    padding: 3px;
                    margin-left: -1px;
                    margin-top: -8px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_oliverfutur.png'" style="
                            position: relative;
                            top: 3px;
                            width: 60px;
                            height: 36px;
                            margin: 4px;
                            margin-left: 24px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                height: 39px;
                padding: 7px;
                margin-left: 4px;
                margin-top: -1px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_palomares.png'" style="
                                position: relative;
                                top: 3px;
                                width: 108px;
                                height: 36px;
                                margin: 4px;
                                margin-left: 5px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                                    height: 38px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_prosanzcu.png'" style="
                                position: relative;
                                top: 3px;
                                width: 85px;
                                height: 36px;
                                margin: 4px;
                                margin-left: 5px;
                                display: inline-block;
                                overflow: hidden;
                                background-color: white;
                                border-radius: 25px;">
                            <img style="
                                    height: 38px;
                                    margin-left: 6px;
                                    padding: 3px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_brio.png'" style="
                                position: relative;
                                top: 3px;
                                left: 8px;
                                width: 85px;
                                height: 36px;
                                display: inline-block;
                                margin-right: 0;
                                overflow: hidden;">
                            <img style="
                                    height: 38px;
                                    padding: 3px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ortizlavado.png'" style="
                            position: relative;
                            top: 3px;
                            width: 120px;
                            margin: 4px;
                            margin-left: 5px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                                        height: 39px;
                                    margin-left: 6px;
                                    padding: 2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo-simat-agricola.png'" style="
                            position: relative;
                            top: 3px;
                            width: 120px;
                            margin: 4px;
                            margin-left: 20px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                        height: 40px;
                                    margin-left: 6px;
                                    padding: 2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ferticinca.png'" style="
                            position: relative;
                            top: 3px;
                            width: 120px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                        height: 40px;
                                    padding: 2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo-lafast.png'" style="
                            position: relative;
                            top: 3px;
                            width: 120px;
                            margin: 4px;
                            margin-left: 5px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                        height: 40px;
                                    margin-left: 20px;
                                    padding: 2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_icod.png'" style="
                            position: relative;
                            top: 3px;
                            width: 120px;
                            margin: 4px;
                            margin-left: 5px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                        height: 40px;
                                    margin-left: 6px;
                                    padding: 2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ebro.png'" style="
                            position: relative;
                            top: 3px;
                            width: 203px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                                height: 45px;
                                padding: 6px;
                                margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_hernandorena.png'" style="
                            position: relative;
                            top: 0px;
                            left: 5px;
                            width: 86px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 46px;
                                padding: 7px;
                                margin-top: -4px;
                                margin-left: -3px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_donjardin.jpg'" style="
                            position: relative;
                            top: 0px;
                            left: 5px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 46px;
                                padding: 7px;
                                margin-top: -4px;
                                margin-left: -3px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_pascualcabedo.png'" style="
                               position: relative;
                                top: 3px;
                                width: 72px;
                                height: 38px;
                                margin-left: 14px;
                                margin-right: 16px;
                                display: inline-block;
                                overflow: hidden;
                                background: white;
                                border-radius: 1px;">
                            <img style="
                                height: 45px;
                                padding: 6px;
                                margin-left: -2px;
                                margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_saviatropical.png'" style="
                            position: relative;
                            top: 0px;
                            left: 5px;
                            width: 102px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 6px;
                                margin-top: -5px;
                                margin-left: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_manezylozano.png'" style="
                            position: relative;
                            top: 0px;
                            left: 5px;
                            width: 84px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 6px;
                                margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_previlsa.png'" style="
                            position: relative;
                            top: 2px;
                            left: 1px;
                            width: 132px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 6px;
                                margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_buitech.png'" style="
                            position: relative;
                            top: 2px;
                            left: 1px;
                            width: 132px;
                            height: 38px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 6px;
                                margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_laplana.png'" style="
                            position: relative;
                            top: 2px;
                            left: 4px;
                            width: 104px;
                            height: 38px;
                            margin: 4px;
                            margin-right: 35px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 8px;
                                margin-top: -6px;
                                margin-left: -2px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_dqvademecum.png'" style="
                            position: relative;
                            top: 2px;
                            left: 15px;
                            width: 126px;
                            height: 38px;
                            margin: 4px;
                            margin-right: 35px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 3px;">
                            <img style="
                                height: 48px;
                                padding: 6px;
                                margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_esparragosgranada.png'" style="
                            position: relative;
                            top: 3px;
                            width: 97px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 25px;">
                            <img style="
                                height: 82px;
                                padding: 0px;
                                margin-left: 10px;
                                margin-top: -21px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_citricos_ginero_horizontal.png'" style="
                            position: relative;
                            top: 3px;
                            width: 158px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;
                            border-radius: 2px;">
                            <img style="
                                height: 55px;
                                padding: 0px;
                                margin-left: 3px;
                                margin-top: -10px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_naturfrut.png'" style="
                            position: relative;
                            width: 160px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            border-radius: 25px;">
                            <img style="
                                height: 45px;
                                padding: 7px;
                                margin-left: -4px;
                                margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_innovia.png'" style="
                            position: relative;
                            top: 3px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;">
                            <img style="
                            height: 45px;
                            padding: 7px;
                            margin-left: -4px;
                            margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_abonosfolques.png'" style="
                            position: relative;
                            top: 3px;
                            width: 95px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;
                            background-color: white;">
                            <img style="
                                height: 44px;
                                padding: 7px;
                                margin-left: 1px;
                                margin-top: -4px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_armentia.png'" style="
                            position: relative;
                            top: 3px;
                            width: 95px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                background-color: white;
                                height: 45px;
                                padding: 4px;
                                margin-left: 17px;
                                margin-top: -8px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ava.png'" style="
                            position: relative;
                            top: 3px;
                            width: 95px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                background-color: white;
                                height: 45px;
                                padding: 4px;
                                margin-left: 17px;
                                margin-top: -8px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_burriana.jpg'" style="
                            position: relative;
                            top: 3px;
                            width: 300px;
                            height: 36px;
                            margin: 4px;
                            display: inline-block;
                            overflow: hidden;">
                            <img style="
                                border-radius: 25px;
                                background-color: white;
                                height: 45px;
                                padding: 4px;
                                margin-left: 17px;
                                margin-top: -3px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ctl.png'" style="
                        position: relative;
                        top: 3px;
                        width: 150px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_fotoagricultura.png'" style="
                        position: relative;
                        width: 150px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_lluchychulbi.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agropecuariaramirogruas.png'" style="
                        position: relative;
                        width: 150px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_viverospereira.png'" style="
                        position: relative;
                        width: 100px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_pedrosaez.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_campanaragricola.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" [src]="logoUrl" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_valencianagestionagraria_vertical.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_valencianagestionagraria_horizontal.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_hopsteiner.png'" style="
                        position: relative;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            width: 150px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_hopsteiner.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_cartagenafresh.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_cartagenafresh.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_plantanova.png'" style="
                        position: relative;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            width: 100px;
                            height: 40px;
                            padding: 1px;
                            border-radius: 5px;
                            margin-left: 15px;" src="assets/logo_plantanova.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_bambunova.png'" style="
                        position: relative;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            width: 100px;
                            height: 40px;
                            border-radius: 5px;
                            padding: 1px;
                            margin-left: 15px;" src="assets/logo_bambunova.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_jardinpn.png'" style="
                        position: relative;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            width: 100px;
                            height: 40px;
                            padding: 1px;
                            border-radius: 5px;
                            margin-left: 15px;" src="assets/logo_jardinpn.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_gragon.png'" style="
                        position: relative;
                        width: 100px;
                        height: 40px;
                        display: inline-block;">
                            <img style="
                            background-color: white;
                            width: 70px;
                            height: 45px;
                            padding: 1px;
                            margin-left: 17px;
                            margin-top: -2px;
                            border-radius: 5px;" src="assets/logo_gragon.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_verdurasbelloch.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_verdurasbelloch.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_yagoaznar.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_yagoaznar.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agufert.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_agufert.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agropedbel.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_agropedbel.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_prodalbar.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_prodalbar.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_sequiador.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_sequiador.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_gfcartera.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_gfcartera.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_brimel.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_brimel.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_ingenia.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_ingenia.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agrosol.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_agrosol.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_intasa.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_intasa.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_coliberica.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_coliberica.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_mantengarden.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_mantengarden.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agrosalvi.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_agrosalvi.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_mbcpomelos.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_mbcpomelos.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_seteo.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_seteo.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_salf.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_salf.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_servalbert.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_servalbert.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_sirga.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_sirga.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_agroleza.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_agroleza_horizontal.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_tousagricola.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_tousagricola.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_benibal.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_benibal_horizontal.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_valdepalma.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_valdepalma.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_valdepalmafitosgest.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_valdepalmafitosgest.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_democropgest.png'" style="
                        position: relative;
                        width: 200px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_democropgest.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_black.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_black.png" alt="">
                        </div>
                        <div *ngSwitchCase="'assets/logo_gruesnadal.png'" style="
                        position: relative;
                        width: 400px;
                        height: 40px;
                        margin: 4px;
                        display: inline-block;
                        overflow: hidden;">
                            <img style="
                            background-color: white;
                            height: 45px;
                            padding: 0px;
                            margin-left: 17px;
                            margin-top: -5px;" src="assets/logo_gruesnadal.png" alt="">
                        </div>
                    </div>
                </a>
                <div *ngIf="hasClients && userCanSeeClientes" class="client-selected">
                    <button style="font-size: 0.8em;opacity: .65;"
                        class="btn btn-light d-flex flex-direction-column align-items-center gap-2"
                        [disabled]="!dashboardEvent.changeClientActive" (click)="changeClient()">
                        <i class="fa fa-user"></i>
                        <span *ngIf="clientes.selected">
                            {{clientes.selected.razon_social || clientes.selected.nombre}}
                        </span>
                    </button>
                </div>
            </div>

            <div class="header">
                <h5>{{dashboardEvent.headerTitle}}</h5>
            </div>

            <div style="margin-right: 20px; color: white">
                <span style="position: relative; bottom: 2px">{{currentUser.nombre }} {{currentUser.apellidos}}</span>
                <i class="fa fa-bars" (click)="toggleMenu(menu, $event)" style="
                    color: white;
                    font-size: 1.5em;
                    margin-left: 10px">
                </i>
                <p-tieredMenu #menu [model]="popupItems" [popup]="true"></p-tieredMenu>
            </div>

        </div>
    </div>
</div>

<p-dialog header="Mi perfil" [(visible)]="showClientProfile" (onShow)="scrollToTop()">
    <div *ngIf="!isComunero" style="width: 600px; height: 420px">
        <div style="
        display: flex;
        align-content: center;
        justify-content: space-between;">
            <p class="font-weight-bold">{{currentUser.email || 'Usuario sin email'}}</p>
            <div *ngIf="showUsuariosFirma">
                <div *ngIf="currentUser.firma">
                    <button *ngIf="currentUser?.firma" class="btn btn-link" (click)="verFirma()"
                        style="position: relative; bottom: 7px;">Firma</button>
                </div>
            </div>
        </div>

        <app-form-builder [model]="currentUser" [undoEnabled]="false" [distributionColumns]="2" [fields]="formFields"
            (formChanges)="formChanges($event)">
        </app-form-builder>
        <app-form-builder [model]="currentUser" [undoEnabled]="false" [distributionColumns]="2" [fields]="formFields2"
            (formChanges)="formChanges($event)">
        </app-form-builder>
        <div class="alert alert-light text-secondary p-2 mt-3 small">
            <p *ngIf="appName !== 'viverospereira'">Los cambios tendrán efecto cuando vuelva a iniciar sesión.</p>
            <p *ngIf="appName === 'viverospereira'">8 MB de 1 GB usados.</p>
        </div>

        <br>
        <div *ngIf="showUsuariosFirma" class="w-100 mb-4 d-flex justify-content-center">
            <div>
                <h6>Firma</h6>
                <div class="canvas-wrap">
                    <app-signature #sp (onEndEvent)="drawComplete(sp)"></app-signature>
                </div>
                <button data-html2canvas-ignore="true" class="p-0 m-0 btn btn-link"
                    (click)="clearSigPad(sp)">Borrar</button>
            </div>
        </div>
    </div>

    <div *ngIf="isComunero" style="width: 600px; height: 420px">
        <div style="
        display: flex;
        align-content: center;
        justify-content: space-between;">
            <p class="font-weight-bold">{{currentClient?.email || 'Usuario sin email'}}</p>
        </div>

        <app-form-builder [model]="currentClient" [undoEnabled]="false" [distributionColumns]="2" [fields]="formClient"
            (formChanges)="formChanges($event)">
        </app-form-builder>

    </div>

    <p-footer>
        <div class="d-flex justify-content-center">
            <button style="margin-right: 1rem" class="btn btn-dark mx-2" (click)="closeClientProfile();">
                Volver
            </button>
            <button *ngIf="!actualizando" class="btn btn-primary mx-2" (click)="saveProfileEdit();">
                <i class="fa fa-pencil" aria-hidden="true"></i>
                Actualizar
            </button>
            <button *ngIf="actualizando" class="btn btn-primary mx-2" disabled>
                <div class="spinner-border spinner-border-sm" role="status"></div>
                Actualizando...
            </button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="Publicidad" [(visible)]="showAdvertisingForm" (onShow)="scrollToTop()">
    <div>
        <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*" />
        <button *ngIf="anuncioImagen" class="btn btn-link" (click)="verAdjunto()">Ver</button>
        <input type="button" class="btn btn-success" style='border-radius: 5px;
            font-size: 1em;
            padding: 6px 12px;
            font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;' value="Añadir foto"
            (click)="readUrl(adjuntarFoto)" />
    </div>
    <br>
    <br>
    <h3>Descripción</h3>
    <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="anuncioTexto"></textarea>
    <div class="alert alert-light text-secondary p-2 mt-3 small">
        El anunció se le aparecerá a los usuarios al iniciar sesión.
    </div>

    <br>
    <div class="d-flex justify-content-center">
        <button style="margin-right: 1rem" class="btn btn-dark mx-2" (click)="closeAdvertisingForm();">
            Volver
        </button>
        <button class="btn btn-primary mx-2" (click)="postAdvertisement();">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            Publicitar
        </button>
    </div>
</p-dialog>

<p-dialog *ngIf="appName === 'agroleza'" header="¡Atención!" [(visible)]="mostrarAnuncioEmergente" [modal]="true"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <img [src]="serverUrl + 'imagenes_publicitarias/' + anuncioImagen" class="anuncio">
    <p style="font-size: 30pt; text-align: center" class="m-0">
        {{anuncioTexto}}
    </p>
</p-dialog>

<p-dialog header="Copia de seguridad" [(visible)]="showBackupDialog" (onShow)="scrollToTop()">
    <div style="width: 380px; height: 220px; display: flex; flex-direction: column; align-items: center;">
        <p style="text-align: center;">
            Se descargará una copia de seguridad de todos los datos almacenados en la base de datos.
            <br><br>
            <small *ngIf="this.applicationType !== 'cropgest'"><b>Esta opción sólo está disponible para usuarios
                    administradores.</b></small>
        </p>

        <br>

        <div class="d-flex justify-content-center" style="margin-bottom: 5%;">
            <button style="margin-right: 1rem" class="btn btn-dark mx-2" (click)="closeBackupDialog();">
                Volver
            </button>
            <button class="btn btn-primary mx-2" (click)="downloadBackup()" [disabled]="isDownloadingCsv">
                <i class="fa fa-download" aria-hidden="true"></i>
                {{isDownloadingCsv ? 'Generando csv...' : 'Descargar'}}
            </button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Actividad de los usuarios" [(visible)]="showVademecumDialog" (onShow)="scrollToTop()">
    <div style="width: 80vw !important;">
        <div style="height: 220px; overflow-y: auto; overflow-x: hidden;">
            <table id="table-vademecum" class="table table-hover">
                <thead>
                    <th>Usuario</th>
                    <th>Producto</th>
                    <th>Referencia</th>
                    <th>Fecha</th>
                    <th>Búsqueda</th>
                    <th>Acción</th>
                </thead>
                <tbody>
                    <tr *ngFor="let row of userActivity">
                        <td>{{row?.nombre_usuario}}</td>
                        <td>{{row?.nombre_producto}}</td>
                        <td>{{row?.referencia}}</td>
                        <td>{{row?.fecha}}</td>
                        <td>{{row?.texto_buscar}}</td>
                        <td>{{row?.accion}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="my-2 d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showVademecumDialog = false">
                Volver
            </button>
            <button class="btn btn-primary mx-2" (click)="downloadVademecumActivity()" [disabled]="isDownloadingCsv">
                <i class="fa fa-download" aria-hidden="true"></i>
                {{isDownloadingCsv ? 'Generando CSV...' : 'Descargar'}}
            </button>
        </div>
    </div>
</p-dialog>

<p-dialog header="Mi listado de productos" [(visible)]="showVademecumListadoDialog" (onShow)="scrollToTop()">
    <div style="width: 80vw !important;">
        <div style="overflow-y: scroll;">
            <div class="mb-3 d-flex justify-content-between align-items-center">
                <span style="font-size: 1.1rem;">Productos</span>
                <div class="d-flex">
                    <button class="mr-2 btn btn-primary" style="font-size: .7rem; padding: 4px 8px;"
                        (click)="pasteListadoProductos()">
                        Añadir productos copiados
                    </button>
                    <button class="btn btn-success" style="font-size: .7rem; padding: 4px 8px;"
                        (click)="saveListadoProductos()"
                        [disabled]="productosApi.listadoVademecum.PUT.isLoading || productosApi.listadoVademecum.POST.isLoading">
                        {{
                        productosApi.listadoVademecum.PUT.isLoading || productosApi.listadoVademecum.POST.isLoading
                        ? 'Guardando...'
                        : 'Guardar listado'
                        }}
                    </button>
                </div>
            </div>
            <p-chips #pChips [(ngModel)]="listadoProductos.productos" [style]="{'height': '40vh'}">
            </p-chips>
        </div>

        <div class="my-2 d-flex justify-content-center">
            <button class="btn btn-dark mx-1" (click)="showVademecumListadoDialog = false">
                Volver
            </button>
            <button class="btn btn-primary mx-1" (click)="printListadoProductos()" [disabled]="isDownloadingCsv">
                <i class="fa fa-download" aria-hidden="true"></i>
                {{
                isDownloadingCsv
                ? isGeneratingCsv
                ? 'Descargando usos... ' + (productosCSV.length || 0) + '/' + (listadoProductos?.productos?.length ||
                0)
                : 'Generando csv...'
                : 'Descargar'
                }}
            </button>
        </div>
    </div>
</p-dialog>


<div *ngIf="showSidebar && (visibleItems > 1)" class="custom-sidebar">
    <div class="content">
        <div *ngFor="let item of dashboardEvent.menuItemData">
            <ng-container
                *ngIf="item !== undefined && item !== null && (item.visible === undefined || item.visible === true) ">
                <app-sidenav-cell [id]="item.id" [text]="item.text" [icon]="item.icon"
                    [isSelected]="item.isSelected" [expand]="item.expandable"
                    (cellClickHandler)="onNavItemClick($event)" (subcellClickHandler)="onSubNavItemClick($event)">
                </app-sidenav-cell>
            </ng-container>
        </div>
    </div>
</div>

<p-toast styleClass="custom-toast" [style]="{'zIndex': '1010'}"></p-toast>

<!-- Watermark -->
<div *ngIf="showWatermark" style="text-align: right;position: fixed;z-index:9999999;bottom: 1%;width: auto;right: 1%;
    cursor: pointer;line-height: 0;display:block !important; opacity: 0.8;">
    <a title="Aplicación creada por Locatec. Todos los derechos reservados." target="_blank" href="https://locatec.es/">
        <img [src]="urlLogoLocatec" alt="Locatec.es">
    </a>
</div>

<a href="#" id="csvLink" style="visibility: hidden; position: fixed;"></a>