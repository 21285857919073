import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseForm } from '../../../../base-form';
import { list, stack, build } from '../../../../../common/classes/request-builder';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { TratamientosApiService } from 'src/app/service/api/tratamientos-api.service';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { environment } from 'src/environments/environment';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { Utils } from '../../../../../common/utils';
import { jsPDF } from 'jspdf';
import { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { TratamientosControlModel } from 'src/app/models/tratamientos/tratamientos-control.model';
import { TareasBioModel } from 'src/app/models/tareas/tareas-bio.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ProductosList } from 'src/app/models/stock/productos-list.model';

moment.locale('es');

@Component({
    selector: 'app-control-tratamientos-form',
    templateUrl: './control-form.component.html',
    styleUrls: ['./control-form.component.scss']
})
export class TratamientosControlFormComponent extends BaseForm implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton: RequestButtonComponent = new RequestButtonComponent;

    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public parcelasName = environment.features.parcelasName;

    public isGeneratingCanvas = false;
    public showEliminarControl = false;
    public formType = 0;

    public override pathToGoBack = 'tratamientos-control';
    public params: TratamientosControlModel = {};

    public override model: TratamientosControlModel = {
        estado: 'Planificado'
    };

    public formRequest: AppFormRequest = new AppFormRequest();
    public fincas: TypeAheadInterface<FincasModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel[]> = list();
    public maquinarias: TypeAheadInterface<MaquinariaModel> = list();
    public productos: TypeAheadInterface<ProductosModel> = list();
    public productos1: TypeAheadInterface<TareasBioModel> = list();
    public productos2: TypeAheadInterface<TareasBioModel> = list();
    public productos3: TypeAheadInterface<TareasBioModel> = list();
    public usos1: TypeAheadInterface<ProductosList> = list();
    public usos2: TypeAheadInterface<ProductosList> = list();
    public usos3: TypeAheadInterface<ProductosList> = list();
    public plagas1: TypeAheadInterface<ProductosList> = list();
    public plagas2: TypeAheadInterface<ProductosList> = list();
    public plagas3: TypeAheadInterface<ProductosList> = list();
    public usosProd: TypeAheadInterface<ProductosList> = list();
    public agentesProd: TypeAheadInterface<ProductosList> = list();
    public udsProd: TypeAheadInterface<ProductosList> = list();
    public dosisProd: TypeAheadInterface<ProductosList> = list();


    public estados = list('Planificado', 'Orden ficha', 'Aplicado');
    public prioridades = list(['...', null], 'Día', 'Semana', 'Quincena', 'Mensual');

    public receta: TratamientosControlModel = {};
    public showReceta = false;

    public formFields: ItemInterface<object>[] = [
        {
            label: this.fincaName,
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id'
        },
        {
            label: this.parcelasName,
            field: 'ids_parcelas',
            inputType: {type: InputType.MULTISELECT},
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: TratamientosControlModel; }) => it.value.id_finca === +(this.fincas.selected as FincasModel).id
        },
        {
            label: 'Fecha',
            field: 'fecha',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        {
            label: 'Estado',
            field: 'estado',
            inputType: {type: InputType.DROPDOWN},
            values: this.estados,
        },

        {
            label: 'Nombre',
            field: 'plagas',
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'litros',
            label: 'Litros de caldo',
            inputType: {type: InputType.EDIT_NUMERIC},
        },
        {
            field: 'ph_caldo',
            label: 'pH Caldo',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'hernandorena',
        },
        {
            field: 'ph_agua',
            label: 'pH Agua',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'hernandorena',
        },
        {
            field: 'ph_final',
            label: 'pH Final',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.appName === 'hernandorena',
        },
        {
            label: 'Maquinaria',
            field: 'id_maquinaria',
            inputType: {type: InputType.DROPDOWN},
            values: this.maquinarias,
            valuePrimaryKey: 'id',
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA},
        },
        {
            label: 'EPI',
            field: 'epi',
            inputType: {type: InputType.EDIT_TEXT}
        }

    ];


    public formFieldsDosis: ItemInterface<object>[] = [
        {
            label: 'Materia activa 1',
            field: 'id_producto1',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos1,
            valuePrimaryKey: 'id'
        },
        {
            label: 'Uso',
            field: 'uso1',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.usos1,
        },
        {
            label: 'Plaga',
            field: 'plaga1',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.plagas1
        },
        {
            label: 'Dosis cuaderno',
            field: 'dosis_cuaderno1',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Cuaderno',
            field: 'ud_cuaderno_1',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Dosis aplicada',
            field: 'dosis1',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Aplicada',
            field: 'ud_real_1',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            hasLabel: false,
            field: 'recomendacion1',
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            label: 'Materia activa 2',
            field: 'id_producto2',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos2,
            valuePrimaryKey: 'id'
        },
        {
            label: 'Uso',
            field: 'uso2',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.usos2
        },
        {
            label: 'Plaga',
            field: 'plaga2',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.plagas2
        },
        {
            label: 'Dosis cuaderno',
            field: 'dosis_cuaderno2',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Cuaderno',
            field: 'ud_cuaderno_2',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Dosis real',
            field: 'dosis2',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Aplicada',
            field: 'ud_real_2',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            hasLabel: false,
            field: 'recomendacion2',
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            label: 'Materia activa 3',
            field: 'id_producto3',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos3,
            valuePrimaryKey: 'id'
        },
        {
            label: 'Uso',
            field: 'uso3',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.usos3
        },
        {
            label: 'Plaga',
            field: 'plaga3',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.plagas3
        },
        {
            label: 'Dosis cuaderno',
            field: 'dosis_cuaderno3',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Cuaderno',
            field: 'ud_cuaderno_3',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Dosis real',
            field: 'dosis3',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Ud. Medida Aplicada',
            field: 'ud_real_3',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            hasLabel: false,
            field: 'recomendacion3',
            inputType: {type: InputType.ONLY_TEXT}
        }
    ];


    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tratamientosApi: TratamientosApiService,
        public tareasApi: TareasApiService,
        public formApi: FormCommonApiService,
        public override dashboard: DashboardService) {
        super(
            route,
            router,
            dashboard,
            tratamientosApi.tratamientosControl.PUT,
            tratamientosApi.tratamientosControl.POST,
            'tratamientos-control',
            'Editar Control de Tratamientos',
            'Crear Control de Tratamientos'
        );

        this.params = (this.route.snapshot.pathFromRoot.find(it => it.params['fecha']) as ActivatedRouteSnapshot).params;
        if (this.params) {
            if (this.params.finca) {
                this.model.id_finca = +this.params.finca;
            }
            if (this.params.fecha) {
                this.model.fecha = moment(this.params.fecha, 'DD-MM-YYYY').toDate();
            }
        }
    }

    ngOnInit() {
        this.formType = this.getType();
        if (this.formType === FormRequestTypes.CREATE) {
            this.model.id_maquinaria = localStorage.getItem(this.appName + '_CT_MAQUINARIA_SELECTED') as string;
            // this.model.id_trabajador = localStorage.getItem(this.appName + '_CT_TRABAJADOR_SELECTED');
            this.formRequest.update();
        }

        this.expandFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tratamientosApi.tratamientosControl.GET)
            .setPostRequest(this.tratamientosApi.tratamientosControl.POST)
            .setPutRequest(this.tratamientosApi.tratamientosControl.PUT)
            .setFormFields(this.formFields.concat(this.formFieldsDosis))
            .setFieldsToSend(([
                'id', 
                'id_usuario', 
                'id_cliente', 
                'id_tratamiento', 
                'id_tarea', 
                'cultivo', 
                'variedad', 
                'sup_cultivada'
            ] as string[])
                .concat(this.formFields.map(it => it.field ?? ''))
                .concat(this.formFieldsDosis.map(it => it.field ?? ''))
            );
        this.initFormData();

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public mapProductos(array: {value: TareasBioModel; label: string; tipo: string}[]) {
        array.map(it => {
            let level = '';
            let numreg = '';
            if (it.value && it.value.producto) {
                level = (Utils.checkIfProductIsValid(it.value.producto.fecha_caducidad, it.tipo) + ' ');
                numreg = it.value.producto.referencia + ' ';
            }
            if (it.label && it.value && (it.label.indexOf(it.value.nombre ?? '') === -1)) {
                it.label = level + it.label + ' (' + numreg + it.value.nombre + ')';
            }
        });
        return array;
    }

    public submit() {
        delete this.model.recomendacion1;
        delete this.model.recomendacion2;
        delete this.model.recomendacion3;

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {

        this.requestButton.error = '';
        if (tag === 'id_maquinaria') {
            localStorage.setItem(this.appName + '_CT_MAQUINARIA_SELECTED', this.model.id_maquinaria ?? '');
        }
        // if (tag === 'id_trabajador') {
        //     localStorage.setItem(this.appName + '_CT_TRABAJADOR_SELECTED', this.model.id_trabajador);
        // }
        if (tag === 'id_finca') {
            this.model.ids_parcelas = '';
        }

        if (tag === 'id_producto1') {
            this.model.producto1 = (this.productos1.selected as TareasBioModel).producto?.nombre_comercial ?? '';
            this.model.materia_activa1 = (this.productos1.selected as TareasBioModel).producto?.composicion ?? '';
            this.getDistinctDatafromProducto((this.productos1.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                () => {
                    (this.formFieldsDosis[1] as ItemInterface<object>).values = this.usosProd;
                    (this.formFieldsDosis[2] as ItemInterface<object>).values = this.agentesProd;
                    return;
                }
            ).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
        if (tag === 'id_producto2') {
            this.model.producto2 = (this.productos2.selected as TareasBioModel).producto?.nombre_comercial ?? '';
            this.model.materia_activa2 = (this.productos2.selected as TareasBioModel).producto?.composicion ?? '';
            this.getDistinctDatafromProducto((this.productos2.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                () => {
                    if (this.formFieldsDosis[9]) {
                        this.formFieldsDosis[9].values = this.usosProd;
                    }
                    if (this.formFieldsDosis[10]) {
                        this.formFieldsDosis[10].values = this.agentesProd;
                    }
                    return;
                }
            ).catch (e => {
                console.log('catch en getDistinctDatafromProducto: ' + e);
            }
            );
        }
        if (tag === 'id_producto3') {
            this.model.producto3 = (this.productos3.selected as TareasBioModel).producto?.nombre_comercial ?? '';
            this.model.materia_activa3 = (this.productos3.selected as TareasBioModel).producto?.composicion ?? '';
            this.getDistinctDatafromProducto((this.productos3.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                () => {
                    if (this.formFieldsDosis[17]) {
                        this.formFieldsDosis[17].values = this.usosProd;
                    }
                    if (this.formFieldsDosis[18]) {
                        this.formFieldsDosis[18].values = this.agentesProd;
                    }
                    return;
                }
            ).catch (e => {
                console.log('catch en getDistinctDatafromProducto: ' + e);
            }
            );
        }

        if (tag === 'plaga1') {
            this.udsProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[2] && element.label === 
                        (this.formFieldsDosis[2].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.ud_cuaderno_1 = element.value as string;
                    this.model.ud_real_1 = 'cc/100l';
                }
            });
            this.dosisProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[2] && element.label === 
                        (this.formFieldsDosis[2].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.recomendacion1 = element.value as string;
                }
            });
        }
        if (tag === 'plaga2') {
            this.udsProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[10] && element.label === 
                        (this.formFieldsDosis[10].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.ud_cuaderno_2 = element.value as string;
                    this.model.ud_real_2 = 'cc/100l';
                }
            });
            this.dosisProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[10] && element.label === 
                        (this.formFieldsDosis[10].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.recomendacion2 = element.value as string;
                }
            });
        }
        if (tag === 'plaga3') {
            this.udsProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[18] && element.label === 
                        (this.formFieldsDosis[18].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.ud_cuaderno_3 = element.value as string;
                    this.model.ud_real_3 = 'cc/100l';
                }
            });
            this.dosisProd.filtered.forEach((element) => {
                if (this.formFieldsDosis[18] && element.label === 
                        (this.formFieldsDosis[18].values as TypeAheadInterface<ProductosList>).selected) {
                    this.model.recomendacion3 = element.value as string;
                }
            });
        }

        if (tag === 'estado' && this.appName === 'hernandorena') {
            //'observaciones',
            const permitidos = [
                'ids_parcelas',
                'ph_caldo',
                'litros',
                'ph_agua',
                'ph_final',
                'nivel_peligrosidad',
                'id_maquinaria',
                'estado'
            ];
            this.formFields.forEach(field => {
                if (permitidos.includes(field.field ?? '')) {
                    field.required = this.model.estado === 'Aplicado' ? true : false;
                }
            });
            this.formRequest.update();
        }
    }


    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public showEliminar() {
        this.showEliminarControl = false;
        this.tratamientosApi.tratamientosControl.DELETE.toPromise(this.model)
            .then(() => {
                window.location.reload();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    }

    public generarReceta() {
        this.receta = Object.create(this.model);

        this.receta.dosis1 = this.receta.dosis1 + ' ' + this.receta.ud_real_1;
        this.receta.dosis2 = this.receta.dosis2 + ' ' + this.receta.ud_real_2;
        this.receta.dosis3 = this.receta.dosis3 + ' ' + this.receta.ud_real_3;

        this.receta.fecha = moment(this.receta.fecha).format('LL');
        this.showReceta = true;
        const elements = document.getElementsByClassName('p-dialog-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);
    }

    public imprimirReceta() {
        this.isGeneratingCanvas = true;
        const config = {
            canvasScale: 2,
            orientation: 'l',
            width: 290
        };

        html2canvas(
            document.getElementById('html2canvasDiv') ?? new HTMLElement, 
            { scale: config.canvasScale, logging: false }
        ).then(canvas => {
            this.isGeneratingCanvas = false;

            const pdf = new jsPDF('l', 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
            pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            pdf.save((this.receta.plagas || '.').replace('.', ''));
            return;
        }).catch (e => {
            console.log('catch en html2canvas: ' + e);
        }
        );
    }

    public async getDistinctDatafromProducto(referencia: string) {
        referencia = '\'' + referencia + '\'';
        await this.formApi.magramaDetalleProducto.toPromise({ referencia: referencia }).then(res => {
            const usoslist = list();
            const agenteslist = list();
            const udslist = list();
            const dosislist = list();

            let auxuso = '';
            const auxagente: string[] = [];

            res.map((uso: ProductosList) => {
                if (uso.uso !== auxuso || auxuso === '') {
                    auxuso = uso.uso ?? '';
                    usoslist.filtered.push({ label: uso.uso, value: uso.uso });
                }
            });

            res.map((agente: ProductosList) => {
                if (!auxagente.includes(agente.agente ?? '')) {
                    auxagente.push(agente.agente ?? '');
                    agenteslist.filtered.push({ label: agente.agente, value: agente.agente });
                    udslist.filtered.push({ label: agente.agente, value: agente.unidad_medida });
                    dosislist.filtered.push({
                        label: agente.agente,
                        value: 'Dosis. ' + agente.dosis1 + '-' + agente.dosis2 +
                            ' ' + agente.unidad_medida + ' Plazo Seg. ' + agente.plazo_seguridad
                    });
                    udslist.filtered.push({ label: agente.agente, value: agente.unidad_medida });
                }
            });

            this.usosProd = usoslist;
            this.agentesProd = agenteslist;
            this.udsProd = udslist;
            this.dosisProd = dosislist;
            return;
        }).catch (e => {
            console.log('catch en magramaDetalleProducto: ' + e);
        }
        );
    }

    override ngOnDestroy() {
        this.tratamientosApi.tratamientosControl.GET.unsuscribe();
        this.tratamientosApi.tratamientosControl.POST.unsuscribe();
        this.tratamientosApi.tratamientosControl.PUT.unsuscribe();
        this.tratamientosApi.tratamientosControl.DELETE.unsuscribe();
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.model.fecha) {
                this.model.fecha = moment(this.model.fecha).toDate();
            }

            resolve(true);
        });

        let registroActualizado = false;
        let model2: TratamientosControlModel = {};
        this.model.fecha = moment(this.model.fecha).format('YYYY-MM-DD');
        const fecha2 = moment(this.model.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY');

        this.formRequest.beforeSend(resolve => {
            const noData = [0, '0', null, undefined, false];

            if (this.parcelas.selected) {
                this.model.cultivo = this.parcelas.selected.map((it: ParcelasModel) => it.cultivo).join(';');
                this.model.variedad = this.parcelas.selected.map((it: ParcelasModel) => it.variedad).join(';');
                this.model.sup_cultivada = this.parcelas.selected
                    .map((it: ParcelasModel) => 
                        parseFloat((it.superficie_cultivada || '')
                            .replace(',', '.') || '0'))
                    .reduce((a: number, b: number) => a + b, 0).toString();
            }

            if ((this.model.estado === 'Aplicado') && (noData.includes(this.model.id_tarea))) {
                model2 = Object.assign({}, this.model);

                const dataToSend: {
                    [key: string]: string,
                    id_producto: string,
                    id_producto1: string,
                    id_producto2: string,
                    id_producto3: string,
                    dosis_cuaderno: string,
                    dosis_cuaderno1: string,
                    dosis: string,
                    dosis1: string,
                    dosis_cuaderno2: string,
                    dosis2: string,
                    dosis_cuaderno3: string,
                    dosis3: string,
                    plaga: string,
                    plaga1: string,
                    plaga2: string,
                    plaga3: string,
                    uso: string,
                    uso1: string,
                    uso2: string,
                    uso3: string,
                    ud_real_1: string,
                    ud_real_2: string,
                    ud_real_3: string,
                    ud_cuaderno_1: string,
                    ud_cuaderno_2: string,
                    ud_cuaderno_3: string,
                    epi: string,
                } = {
                    id_producto: '',
                    id_producto1: '',
                    id_producto2: '',
                    id_producto3: '',
                    dosis_cuaderno: '',
                    dosis_cuaderno1: '',
                    dosis: '',
                    dosis1: '',
                    dosis_cuaderno2: '',
                    dosis2: '',
                    dosis_cuaderno3: '',
                    dosis3: '',
                    plaga: '',
                    plaga1: '',
                    plaga2: '',
                    plaga3: '',
                    uso: '',
                    uso1: '',
                    uso2: '',
                    uso3: '',
                    ud_real_1: '',
                    ud_real_2: '',
                    ud_real_3: '',
                    ud_cuaderno_1: '',
                    ud_cuaderno_2: '',
                    ud_cuaderno_3: '',
                    epi: this.model.epi ?? '',
                };

                for (const key of ['id_producto', 'dosis_cuaderno', 'dosis', 'uso', 'plaga']) {
                    for (const i of [1, 2, 3]) {
                        if (model2[key + i] && !noData.includes(model2['id_producto' + i]?.toString())) {
                            dataToSend[key] += model2[key + i] + ';';
                        }
                    }
                    if (dataToSend[key]) {
                        dataToSend[key] = dataToSend[key]?.slice(0, -1) ?? '';
                    }
                }

                this.tratamientosApi.tratamientosPOST.toPromise({
                    nombre: 'Trat. (auto) ' + model2.plagas,
                    id_finca: model2.id_finca,
                    id_cliente: model2.id_cliente,
                    ids_productos: dataToSend.id_producto,
                    cantidades_cuaderno: dataToSend.dosis_cuaderno,
                    cantidades_real: dataToSend.dosis,
                    nombres_agentes: dataToSend.plaga,
                    nombres_usos: dataToSend.uso,
                    epi: dataToSend.epi
                }).then(res => {
                    if (res && res[0]) {
                        this.model.id_tratamiento = model2.id_tratamiento = res[0].id;
                        registroActualizado = true;
                    } else {
                        alert('Error: no se ha podido actualizar el registro. Por favor, vuelva a intentarlo más tarde.');
                        return;
                    }
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            } else {
                resolve(true);
            }
        });

        if (registroActualizado) {
            this.tareasApi.fitosanitariosPOST.toPromise({
                id_finca: model2.id_finca,
                ids_parcelas: model2.ids_parcelas,
                id_tratamiento: model2.id_tratamiento,
                litros: model2.litros,
                id_maquinaria: model2.id_maquinaria,
                fecha_inicio: fecha2,
                fecha_fin: fecha2,
                id_cliente: model2.id_cliente,
                observaciones: model2.observaciones,
                aplicado: '1',
                eficacia: 'Buena',
            }).then(res2 => {
                if (res2 && res2[0]) {
                    this.model.id_tarea = res2[0].id;
                } else {
                    alert('Error: no se ha podido actualizar el registro. Por favor, vuelva a intentarlo más tarde.');
                    return;
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
    }

    private initFormData() {
        stack([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', false),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true),
            build(this.trabajadoresApi.maquinariaGET, this.maquinarias, 'nombre', false),
            build(this.productosApi.productos.GET, this.productos1, 'materia_activa', false),
            build(this.productosApi.productos.GET, this.productos2, 'materia_activa', false),
            build(this.productosApi.productos.GET, this.productos3, 'materia_activa', false)
        ], () => {
            this.productos1.filtered = this.mapProductos(
                this.productos1.filtered as {value: TareasBioModel; label: string; tipo: string}[]
            ).filter(it => it.label);
            this.productos2.filtered = this.mapProductos(
                this.productos2.filtered as {value: TareasBioModel; label: string; tipo: string}[]
            ).filter(it => it.label);
            this.productos3.filtered = this.mapProductos(
                this.productos3.filtered as {value: TareasBioModel; label: string; tipo: string}[]
            ).filter(it => it.label);

            if (this.getType() === FormRequestTypes.CREATE) {
                this.model['id_maquinaria'] = '9';
            }
            this.formRequest.update();
        });
        if (this.formType === FormRequestTypes.EDIT) {

            if (this.productos1.selected !== undefined) {
                this.getDistinctDatafromProducto((this.productos1.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                    () => {

                        (this.formFieldsDosis[1] as ItemInterface<object>).values = this.usosProd;
                        (this.formFieldsDosis[2] as ItemInterface<object>).values = this.agentesProd;
                        this.formRequest.update();
                        return;
                    }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }
            if (this.productos2.selected !== undefined) {
                this.getDistinctDatafromProducto((this.productos2.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                    () => {
                        if (this.formFieldsDosis[9]) {
                            this.formFieldsDosis[9].values = this.usosProd;
                        }

                        if (this.formFieldsDosis[10]) {
                            this.formFieldsDosis[10].values = this.agentesProd;
                        }
                        this.formRequest.update();
                        return;
                    }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }// if();
            if (this.productos3.selected !== undefined) {
                this.getDistinctDatafromProducto(
                    (this.productos3.selected as TareasBioModel).producto?.referencia?.toString() ?? '').then(
                    () => {
                        if (this.formFieldsDosis[17]) {
                            this.formFieldsDosis[17].values = this.usosProd;
                        }

                        if (this.formFieldsDosis[18]) {
                            this.formFieldsDosis[18].values = this.agentesProd;
                        }
                        this.formRequest.update();
                        return;
                    }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }// if();
        }
    }
}